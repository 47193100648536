import { i18n } from "@bb-ui/i18n/dist";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { CustomGrid, Widget } from "../../../../../components/Widget/Widget";
import { GetLearnInstancesWithMetricsByCaptainIdsQuery } from "../../../../../graphql/types";
import { toDate, toLocalString } from "../../../../../time/time-parser";
import { LearnMetricsPieChart } from "../../../components/LearnMetricsPieChart";

export interface TotalCoursesWidgetProps extends CustomGrid {
  readonly learns: GetLearnInstancesWithMetricsByCaptainIdsQuery["listLearnInstances"]["items"];
  readonly stackCharts: boolean;
}

export const TotalCoursesWidget: FunctionComponent<TotalCoursesWidgetProps> = ({
  learns,
  ...other
}) => {
  const { t } = useTranslation();
  const lastUpdated = toLocalString(
    learns
      .map((learn) => toDate(learn.lastUpdated))
      .reduce((a, b) => (a > b ? a : b), new Date(0)),
    i18n.i18n.language
  );

  let ultraCourseCountStatusClassic = 0;
  let ultraCourseCountStatusPreview = 0;
  let ultraCourseCountStatusUltra = 0;
  let ultraCourseCountStatusUndecided = 0;

  learns.forEach((l) => {
    console.log(l.metrics.courses);

    const course = l.metrics.courses.metrics[0];
    ultraCourseCountStatusClassic += course.ultraCourseCountStatusClassic;
    ultraCourseCountStatusPreview += course.ultraCourseCountStatusPreview;
    ultraCourseCountStatusUltra += course.ultraCourseCountStatusUltra;
    ultraCourseCountStatusUndecided += course.ultraCourseCountStatusUndecided;
  });

  const data = learns
    .map(() => ({
      Original: ultraCourseCountStatusClassic,
      "Ultra Preview": ultraCourseCountStatusPreview,
      Ultra: ultraCourseCountStatusUltra,
      Undecided: ultraCourseCountStatusUndecided,
    }))
    .reduce((coll, metricData) => {
      Object.keys(metricData).forEach((key) => {
        coll[key] =
          (coll[key] ?? 0) + metricData[key as keyof typeof metricData];
      });

      return coll;
    }, {} as { [key: string]: number });

  const totalCourses = Object.keys(data)
    .filter((key) => data[key] != 0)
    .map((key) => ({
      name: key,
      value: data[key],
    }));

  const sortedMetrics: { name: string; value: number }[] = totalCourses.sort(
    (n1, n2) => {
      if (n1.value > n2.value) {
        return 1;
      }
      if (n1.value < n2.value) {
        return -1;
      }
      return 0;
    }
  );

  return (
    <Widget
      {...other}
      title={t("learn.metrics.chart.totalCourses.title")}
      titleHelp={t("learn.metrics.chart.totalCourses.help")}
    >
      <LearnMetricsPieChart
        metrics={sortedMetrics}
        lastUpdated={lastUpdated}
        title={t("learn.metrics.chart.totalCourses.title")}
        valueFormatString={t(
          "learn.metrics.chart.totalCourses.valueFormatString"
        )}
        mix={true}
      />
    </Widget>
  );
};
