import {
  Action,
  configureStore,
  EnhancedStore,
  ThunkAction,
} from "@reduxjs/toolkit";
import debounce from "lodash.debounce";
import logger from "redux-logger";
import authReducer from "../features/auth/authSlice";
import learnReducer from "../features/learn/learnSlice";
import appReducer from "./appSlice";

const SESSION_STATE_KEY = "learnMetricsState";
export let preloadedState: Record<string, unknown> | undefined;

try {
  const storedState = sessionStorage.getItem(SESSION_STATE_KEY);
  if (storedState) {
    preloadedState = JSON.parse(storedState);
  }
} catch (err) {
  console.error("Failed to hydrate store with persisted state");
  console.error(err);
}

export const store: EnhancedStore = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    learn: learnReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      thunk: true,
    }).concat(logger);
  },
  devTools: true,
  preloadedState,
});

const persistStore = debounce(() => {
  sessionStorage.setItem(SESSION_STATE_KEY, JSON.stringify(store.getState()));
}, 200);

store.subscribe(() => persistStore());

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
