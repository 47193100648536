import { gql, useApolloClient } from "@apollo/client";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from "@bb-ui/react-library";
import "@js-joda/timezone";
import { ChronoUnit, ZonedDateTime } from "@js-joda/core";
import { Grid, TableContainer, TablePagination } from "@material-ui/core";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { CircularLoadingSuspense } from "../../../components/Loading/CircularLoadingSuspense";
import { Widget } from "../../../components/Widget/Widget";
import { useSuspendedQuery } from "../../../graphql/GraphqlProvider";
import { SupportRequestQuery } from "../../../graphql/types";
import { ErrorBoundary } from "../../errorpages/ErrorBoundary";
import { UnknownErrorPage } from "../../errorpages/UnknownErrorPage";
import { CSVDownload, CSVRow } from "../components/CSV/CSVDownload";
import { TimeSpanPicker } from "../components/TimeSpanPicker";

export enum TimeValues {
  YEAR = 12,
  SEMESTER = 6,
  QUARTER = 3,
  MONTH = 1,
}
const tableHeaders = ["caseId", "contactName", "summary", "closeDate"];
const availableTimeSpans = [
  {
    label: "learn.components.timeSpanPicker.values.monthly.year",
    value: TimeValues.YEAR,
  },
  {
    label: "learn.components.timeSpanPicker.values.monthly.semester",
    value: TimeValues.SEMESTER,
  },
  {
    label: "learn.components.timeSpanPicker.values.monthly.quarter",
    value: TimeValues.QUARTER,
  },
  {
    label: "learn.components.timeSpanPicker.values.monthly.month",
    value: TimeValues.MONTH,
  },
];

export const ClientSupportBreakDown = () => (
  <CircularLoadingSuspense>
    <ErrorBoundary fallback={<UnknownErrorPage />}>
      <ClientSupportBreakDownContent />
    </ErrorBoundary>
  </CircularLoadingSuspense>
);

export const ClientSupportBreakDownContent = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [timeValue, setTimeValue] = useState(TimeValues.MONTH);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const client = useApolloClient();

  const [start, setStart] = useState(
    ZonedDateTime.now()
      .minusMonths(1)
      .truncatedTo(ChronoUnit.DAYS)
      .toOffsetDateTime()
      .toString()
  );
  const end = ZonedDateTime.now()
    .truncatedTo(ChronoUnit.DAYS)
    .toOffsetDateTime()
    .toString();

  const captainId: string = searchParams.get("captainId")
    ? searchParams.get("captainId")
    : useAppSelector((s) => s.learn.captainId);
  const handleTimeSpanChange = (value: any) => setTimeValue(value);

  useEffect(() => {
    switch (timeValue) {
      case TimeValues.MONTH:
        setStart(
          ZonedDateTime.now()
            .minusMonths(1)
            .truncatedTo(ChronoUnit.DAYS)
            .toOffsetDateTime()
            .toString()
        );
        break;
      case TimeValues.QUARTER:
        setStart(
          ZonedDateTime.now()
            .minusMonths(3)
            .truncatedTo(ChronoUnit.DAYS)
            .toOffsetDateTime()
            .toString()
        );
        break;
      case TimeValues.SEMESTER:
        setStart(
          ZonedDateTime.now()
            .minusMonths(6)
            .truncatedTo(ChronoUnit.DAYS)
            .toOffsetDateTime()
            .toString()
        );
        break;
      case TimeValues.YEAR:
        setStart(
          ZonedDateTime.now()
            .minusMonths(12)
            .truncatedTo(ChronoUnit.DAYS)
            .toOffsetDateTime()
            .toString()
        );
        break;
    }
  }, [timeValue]);
  const { clientSupportCases } = useSuspendedQuery<
    SupportRequestQuery,
    {
      limit?: number;
      offset?: number;
      captainId: string;
      start: string;
      end: string;
    }
  >(
    gql`
      query q(
        $captainId: String
        $start: AWSDateTime
        $end: AWSDateTime
        $limit: Int
        $offset: Int
      ) {
        clientSupportCases(
          filter: {
            captainId: $captainId
            limit: $limit
            offset: $offset
            start: $start
            end: $end
          }
        ) {
          total
          offset
          limit
          items {
            id
            summary
            contactName
            closedDate
          }
        }
      }
    `,
    {
      variables: {
        captainId: captainId,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        start: start,
        end: end,
      },
    }
  );
  const data = clientSupportCases.items;

  const opts = availableTimeSpans.map((opt) => ({
    label: t(opt.label),
    value: opt.value,
  }));
  return (
    <Box mt={2}>
      <Grid container spacing={3} justifyContent={"flex-end"}>
        <Widget
          variant={"bordered"}
          xs={12}
          title={t("learn.supportRequest.title")}
          titleHelp={t("learn.supportRequest.help")}
        >
          <Grid item container justifyContent={"flex-end"} spacing={2}>
            <Grid item>
              <CSVDownload
                help={t("learn.supportRequest.controls.downloadCSV.help")}
                data-test={`support-csv-button`}
                prefixName={`support-requests`}
                read={async () => {
                  const response = await client.query<SupportRequestQuery>({
                    query: gql`
                      query q(
                        $captainId: String
                        $start: AWSDateTime
                        $end: AWSDateTime
                        $limit: Int
                        $offset: Int
                      ) {
                        clientSupportCases(
                          filter: {
                            captainId: $captainId
                            limit: $limit
                            offset: $offset
                            start: $start
                            end: $end
                          }
                        ) {
                          items {
                            id
                            summary
                            contactName
                            closedDate
                          }
                        }
                      }
                    `,
                    variables: {
                      captainId: captainId,
                      limit: 1000,
                      offset: 0,
                      start: start,
                      end: end,
                    },
                  });
                  return (
                    response.data?.clientSupportCases?.items.map((row) => {
                      const temp: CSVRow = {};
                      temp[t("learn.supportRequest.csv.headers.id")] = row.id;
                      temp[t("learn.supportRequest.csv.headers.summary")] =
                        row.summary;
                      temp[t("learn.supportRequest.csv.headers.contactName")] =
                        row.contactName;
                      temp[t("learn.supportRequest.csv.headers.closedDate")] =
                        row.closedDate;
                      return temp;
                    }) ?? []
                  );
                }}
              />
            </Grid>
            <Grid item>
              <TimeSpanPicker
                supportedTimeSpans={opts}
                onChange={handleTimeSpanChange}
                value={timeValue}
              />
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table data-test={"support-table"}>
                  <TableHead>
                    <TableRow>
                      {tableHeaders.map((label) => (
                        <TableCell>
                          {t(`learn.supportRequest.table.headers.${label}`)}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.map((obj) => (
                      <TableRow>
                        <TableCell>{obj.id}</TableCell>
                        <TableCell>{obj.contactName}</TableCell>
                        <TableCell>{obj.summary}</TableCell>
                        <TableCell>{obj.closedDate}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        colSpan={3}
                        count={clientSupportCases.total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(_, value) => setPage(value)}
                        onRowsPerPageChange={(event) => {
                          setPage(0);
                          setRowsPerPage(parseInt(event.target.value, 10));
                        }}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Widget>
      </Grid>
    </Box>
  );
};
