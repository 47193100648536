import { i18n } from "@bb-ui/i18n/dist";
import { CircleFilled } from "@bb-ui/icons/dist/medium";
import { List, ListItem, Typography, useTheme } from "@bb-ui/react-library";
import { purple } from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import numeral from "numeral";
import { Fragment, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import {
  Area,
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getColorForIndex } from "../../../../app/colors";
import { ChartLastUpdated } from "../../../../components/ChartLastUpdated";
import { HelpTooltip } from "../../../../components/HelpTooltip/HelpTooltip";
import { Widget } from "../../../../components/Widget/Widget";
import {
  formatSameDate,
  toDate,
  toLocalString,
} from "../../../../time/time-parser";
import { formatNumber } from "../../../../util/numbers";
import { Learn } from "../../useLearn";
import { CSVDownload, CSVRow } from "../CSV/CSVDownload";
import { LearnMetricsChartTooltip } from "./LearnMetricsChartTooltip";
numeral.nullFormat("N/A");

export interface LearnMetricsChartProps {
  strings: {
    title: string;
    titleHelp: string;
    activeEntitlement?: string;
    activeEntitlementHelp?: string;
    entitlementUtilization?: string;
    entitlementUtilizationHelp?: string;
    peakUtilizationLabel?: string;
    peakUtilizationHelp?: string;
    totalUtilizationHelp?: string;
    entitlementViolationLabel?: string;
    entitlementViolationHelp?: string;
  };
  valueFormatString: string;
  valueSuffixString?: string;
  showEntitlementLine?: boolean;
  stackCharts?: boolean;
  entitled?: number;
  today: number;
  data: {
    learn: Omit<Learn, "support">;
    metrics: {
      date: string;
      captainId: string;
      title: string;
      total: number;
    }[];
  }[];
  mix?: boolean;
}

const useStyles = makeStyles((theme) => ({
  legend: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    maxWidth: "50%",
    "& > li": {
      width: "inherit",
    },
  },
  legendIcon: {
    paddingRight: theme.spacing(1),
  },
  infoTable: {
    borderLeftStyle: "solid",
    borderLeftColor: purple[500],
    borderLeftSize: 4,
  },
  graph: {
    marginTop: theme.spacing(4),
    minHeight: 300,
    height: "100%",
    width: "100%",
  },
  messages: {
    minHeight: 16,
    fontSize: 14,
    color: theme.palette.error.main,
  },
}));

export const LearnMetricsChart: FunctionComponent<LearnMetricsChartProps> = ({
  data,
  entitled,
  strings,
  showEntitlementLine,
  valueFormatString,
  valueSuffixString,
  stackCharts,
  mix,
  ...other
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  let entitlementViolators: string[] = [];
  let peakYValue = 0;
  let lastUpdated = "";

  const metrics = Object.values(
    data.reduce(
      (
        a: Record<
          string,
          { date: string; peak: number } & Record<string, number | string>
        >,
        b
      ) => {
        let peakDuringPeriod = 0;

        b.metrics.forEach((metric) => {
          if (!a[metric.date]) {
            a[metric.date] = {
              date: metric.date,
              [b.learn.captainId]: metric.total,
              peak: metric.total,
            };
          } else {
            a[metric.date][b.learn.captainId] = metric.total;
            a[metric.date].peak = Math.max(
              a[metric.date].peak as number,
              metric.total
            );
          }
          lastUpdated = toLocalString(
            toDate(b.learn.lastUpdated),
            i18n.i18n.language
          );

          peakDuringPeriod = Math.max(peakDuringPeriod, metric.total);
        });

        peakYValue += peakDuringPeriod;

        if (entitled && peakDuringPeriod > entitled) {
          entitlementViolators.push(b.learn.captainId);
        }

        return a;
      },
      {}
    )
  ).reverse();

  const avgLearnSizes = data.reduce(
    (a: Record<string, { avg: number; learn: Omit<Learn, "support"> }>, b) => {
      const sum = b.metrics.reduce((c, d) => c + d.total, 0);

      a[b.learn.captainId] = { avg: sum / b.metrics.length, learn: b.learn };
      return a;
    },
    {}
  );

  const sortedLearns = Object.keys(avgLearnSizes)
    .sort((a, b) => {
      if (avgLearnSizes[a].avg === avgLearnSizes[b].avg) {
        return 0;
      }

      return avgLearnSizes[a].avg > avgLearnSizes[b].avg ? -1 : 1;
    })
    .map((captainId) => avgLearnSizes[captainId].learn);

  const showEntitlementData = showEntitlementLine && entitled && entitled > 0;

  return (
    <Widget
      test-data="chart-test"
      title={strings.title}
      titleHelp={strings.titleHelp}
      {...other}
      xs={12}
    >
      <Grid container item xs={12} direction={"row"} spacing={1}>
        {(() =>
          sortedLearns.map((learn, i) => {
            return (
              <Grid item className={classes.legend} key={`point-${i}`}>
                <CircleFilled
                  style={{ color: getColorForIndex(i) }}
                  className={classes.legendIcon}
                />
                {learn.url}
              </Grid>
            );
          }))()}
      </Grid>
      <Grid
        container
        item
        xs={9}
        className={classes.infoTable}
        spacing={1}
        justifyContent="space-between"
      >
        <Grid item xs={3}>
          <Typography variant={"subtitle1"}>
            {formatNumber(valueFormatString, peakYValue)} {valueSuffixString}
          </Typography>
          <HelpTooltip
            help={
              strings.peakUtilizationHelp ??
              t("learn.metrics.chart.peakUtilizationHelp")
            }
          >
            <Typography variant={"subtitle2"}>
              {strings.peakUtilizationLabel ??
                t("learn.metrics.chart.peakUtilizationLabel")}
            </Typography>
          </HelpTooltip>
        </Grid>
        {showEntitlementData && (
          <Fragment>
            <Grid item xs={3}>
              <Typography variant={"subtitle1"}>
                {formatNumber(valueFormatString, entitled)} {valueSuffixString}
              </Typography>
              <HelpTooltip
                help={
                  strings.activeEntitlementHelp ??
                  t("learn.metrics.chart.activeEntitlementHelp")
                }
              >
                <Typography variant={"subtitle2"}>
                  {t("learn.metrics.chart.activeEntitlement")}
                </Typography>
              </HelpTooltip>
            </Grid>
            <Grid item xs={3}>
              <Typography variant={"subtitle1"}>
                {numeral(
                  metrics[metrics.length - 1].peak / (entitled ?? 1)
                ).format("(0.000%)")}
              </Typography>
              <HelpTooltip
                help={
                  strings.entitlementUtilizationHelp ??
                  t("learn.metrics.chart.entitlementUtilizationHelp")
                }
              >
                <Typography variant={"subtitle2"}>
                  {strings.entitlementUtilization ??
                    t("learn.metrics.chart.entitlementUtilization")}
                </Typography>
              </HelpTooltip>
            </Grid>
          </Fragment>
        )}
        <Grid item xs>
          <CSVDownload
            data-test={`csv-button`}
            prefixName={strings.title}
            read={async () =>
              metrics.map((obj) => {
                const row: CSVRow = {};
                const { peak, date, ...values } = obj;
                row[t("csv.labels.date")] = date;
                for (const key in values) {
                  const url =
                    sortedLearns.find((l) => l.captainId == key)?.url ?? "";
                  row[url] = obj[key];
                }

                return row;
              })
            }
          />
        </Grid>
      </Grid>
      <Grid item className={classes.messages}>
        {(() =>
          showEntitlementData &&
          entitlementViolators.length > 0 && (
            <HelpTooltip
              icon={false}
              help={
                strings.entitlementViolationHelp ??
                t("learn.metrics.chart.entitlementViolationHelp")
              }
            >
              <Typography variant={"body2"}>
                *{" "}
                {strings.entitlementViolationLabel ??
                  t("learn.metrics.chart.entitlementViolationLabel")}
              </Typography>
            </HelpTooltip>
          ))()}
      </Grid>
      <Grid item xs={12}>
        <ResponsiveContainer minHeight={300} debounce={0.5}>
          <ComposedChart
            data={metrics}
            margin={{
              top: 15,
              right: 10,
              left: 10,
              bottom: 10,
            }}
          >
            <XAxis
              dataKey="date"
              tickFormatter={(timeStr) => {
                return entitled || metrics.length < 30
                  ? formatSameDate(timeStr, "MM/yyyy")
                  : formatSameDate(timeStr, "MM/dd");
              }}
            />
            <YAxis
              dataKey="peak"
              tickFormatter={(val) => formatNumber(valueFormatString, val)}
              domain={["dataMin", stackCharts ? peakYValue : "dataMax"]}
            />
            {(() =>
              showEntitlementLine &&
              entitled &&
              entitled > 0 && (
                <ReferenceLine
                  y={entitled}
                  stroke={
                    entitlementViolators.length > 0
                      ? theme.palette.error.main
                      : theme.palette.success.main
                  }
                  label={"Entitlement"}
                  ifOverflow={"extendDomain"}
                />
              ))()}
            <Tooltip
              content={
                <LearnMetricsChartTooltip
                  entitlement={entitled}
                  formatter={(value) =>
                    `${formatNumber(valueFormatString, value)} ${
                      valueSuffixString ?? ""
                    }`
                  }
                />
              }
            />

            <defs>
              <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset={0}
                  stopColor={theme.palette.error.light}
                  stopOpacity={1}
                />
                <stop
                  offset={0}
                  stopColor={theme.palette.success.light}
                  stopOpacity={1}
                />
              </linearGradient>
            </defs>
            <Area
              dataKey={"entitled"}
              name="Entitled Storage"
              dot={false}
              activeDot={false}
              type="monotone"
              stroke={theme.palette.error.main}
              fill="url(#splitColor)"
              fillOpacity={1}
              stackId={1}
            />
            {(() =>
              sortedLearns.map((learn, i) => {
                const color =
                  mix === undefined
                    ? getColorForIndex(i)
                    : getColorForIndex(i, true);

                return (
                  <Area
                    key={learn.captainId}
                    name={learn.url}
                    dataKey={learn.captainId}
                    dot={true}
                    stroke={color}
                    fill={color}
                    strokeWidth={3}
                    strokeOpacity={0.7}
                    fillOpacity={0.0}
                    stackId={stackCharts ? 2 : undefined}
                  />
                );
              }))()}
          </ComposedChart>
        </ResponsiveContainer>
      </Grid>
      <Grid container direction={"column"} alignContent={"flex-end"}>
        <Grid item>
          <List dense>
            <ListItem>
              <ChartLastUpdated lastUpdated={lastUpdated} />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Widget>
  );
};
