import { FunctionComponent } from "react";
import { CustomGrid } from "../../../../components/Widget/Widget";
import { NewRelicChart } from "../NewRelicChart";

export interface NewrelicWidgetProps extends CustomGrid {
  readonly url: string | null | undefined;
  readonly title: string;
  readonly titleHelp: string;
}

export const NewRelicWidget: FunctionComponent<NewrelicWidgetProps> = ({
  url,
  title,
  titleHelp,
}) => {
  return (
    <NewRelicChart
      title={title}
      titleHelp={titleHelp}
      embeddedChartLink={url}
    />
  );
};
