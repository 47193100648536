import { i18n } from "@bb-ui/i18n/dist";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { CustomGrid, Widget } from "../../../../../components/Widget/Widget";
import { GetLearnInstancesWithMetricsByCaptainIdsQuery } from "../../../../../graphql/types";
import { toDate, toLocalString } from "../../../../../time/time-parser";
import { LearnMetricsPieChart } from "../../../components/LearnMetricsPieChart";

export interface FileCountsByTypeWidgetProps extends CustomGrid {
  readonly learns: GetLearnInstancesWithMetricsByCaptainIdsQuery["listLearnInstances"]["items"];
}

export const FileCountsByTypeWidget: FunctionComponent<
  FileCountsByTypeWidgetProps
> = ({ learns, ...other }) => {
  const { t } = useTranslation();
  const lastUpdated = toLocalString(
    learns
      .map((learn) => toDate(learn.lastUpdated))
      .reduce((a, b) => (a > b ? a : b), new Date(0)),
    i18n.i18n.language
  );

  let imageCount = 0;
  let audioCount = 0;
  let videoCount = 0;
  let docCount = 0;
  let archCount = 0;
  let otherCount = 0;

  learns.forEach((l) => {
    const metric = l.metrics.storage.metrics[0];

    imageCount += metric.fileCounts.images;
    audioCount += metric.fileCounts.audio;
    videoCount += metric.fileCounts.video;
    docCount += metric.fileCounts.documents;
    archCount += metric.fileCounts.archived;
    otherCount += metric.fileCounts.other;
  });

  const data = learns
    .map(() => {
      const row: { [keys: string]: number } = {};
      row[t("learn.metrics.chart.fileCountByType.labels.images")] = imageCount;
      row[t("learn.metrics.chart.fileCountByType.labels.audio")] = audioCount;
      row[t("learn.metrics.chart.fileCountByType.labels.video")] = videoCount;
      row[t("learn.metrics.chart.fileCountByType.labels.documents")] = docCount;
      row[t("learn.metrics.chart.fileCountByType.labels.archived")] = archCount;
      row[t("learn.metrics.chart.fileCountByType.labels.other")] = otherCount;
      return row;
    })
    .reduce((coll, metricData) => {
      Object.keys(metricData).forEach((key) => {
        coll[key] =
          (coll[key] ?? 0) + metricData[key as keyof typeof metricData];
      });

      return coll;
    }, {} as { [key: string]: number });

  const metrics = Object.keys(data)
    .filter((key) => data[key] != 0)
    .map((key) => ({
      name: key,
      value: data[key],
    }));

  const sortedMetrics: { name: string; value: number }[] = metrics.sort(
    (n1, n2) => {
      if (n1.value > n2.value) {
        return 1;
      }
      if (n1.value < n2.value) {
        return -1;
      }
      return 0;
    }
  );

  return (
    <Widget
      {...other}
      title={t("learn.metrics.chart.fileCountByType.title")}
      titleHelp={t("learn.metrics.chart.fileCountByType.help")}
    >
      <LearnMetricsPieChart
        metrics={sortedMetrics}
        lastUpdated={lastUpdated}
        title={t("learn.metrics.chart.fileCountByType.title")}
        valueFormatString={t(
          "learn.metrics.chart.fileCountByType.valueFormatString"
        )}
      />
    </Widget>
  );
};
