import {
  amber,
  blue,
  cyan,
  green,
  indigo,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from "@material-ui/core/colors";

export let CHART_COLORS = [
  orange[600],
  pink[600],
  purple[600],
  red[600],
  teal[600],
  yellow[600],
].sort(() => Math.random() - 0.5);

export let CHART_COLORS_TWO = [
  amber[600],
  blue[500],
  cyan[600],
  green[600],
  indigo[600],
  lime[500],
].sort(() => Math.random() - 0.5);

export function getColorForIndex(index: number, mix?: boolean) {
  if (mix) {
    return CHART_COLORS_TWO[index % CHART_COLORS.length];
  }
  return CHART_COLORS[index % CHART_COLORS.length];
}
