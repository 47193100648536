import React, { useContext } from "react";

export interface IAuth {
  token: string;
  expired: boolean;
  ttlExpired: boolean;
  tenantId: string;
  signOut: () => void;
}

export const AuthContext = React.createContext<IAuth | undefined>(undefined);
export const AccessTokenContext = React.createContext<string | undefined>(
  undefined
);

export const useAppAuth = () => {
  return useContext(AuthContext) as IAuth;
};

export const useAccessToken = () => {
  return useContext(AccessTokenContext) as string;
};
