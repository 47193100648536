import { Picker } from "@bb-ui/react-library";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { CustomGrid, Widget } from "../../../../components/Widget/Widget";
import { ListLearnSiblingsQuery } from "../../../../graphql/types";

export interface ITag {
  label: string;
  value: string;
}

export interface LearnSelectorProps extends Omit<CustomGrid, "onSelect"> {
  captainIds: string[];
  className?: string;
  disabled?: boolean;
  multiSelect?: boolean;
  onSelect: (captainIds: string[] | undefined) => void;
  prodOnly?: boolean;
  siblings: ListLearnSiblingsQuery["listLearnInstances"]["items"] | undefined;
}

export const MultiLearnSelector: FunctionComponent<LearnSelectorProps> = ({
  captainIds,
  className,
  disabled,
  multiSelect,
  onSelect,
  prodOnly,
  siblings,
  ...other
}) => {
  const { t } = useTranslation();

  // const fleetToEnvironment = (fleetName: string) => {};

  const environmentGroups = (siblings ?? []).reduce((collection, l) => {
    let envName = l.environment.split("-")[3] ?? "";

    if (l.environment.startsWith("us-gov")) {
      envName = l.environment.split("-")[4] ?? "" + " (GovCloud)";
    }

    if (!collection[envName]) {
      collection[envName] = [];
    }

    if (prodOnly) {
      if (envName.startsWith("prod") && l.status == "installed") {
        collection[envName].push({
          value: l.captainId,
          label: l.url,
        });
      }
    } else if (l.status == "installed") {
      collection[envName].push({
        value: l.captainId,
        label: l.url,
      });
    }

    return collection;
  }, {} as Record<string, ITag[]>);

  const environments = [
    "playground",
    "performance",
    "dev",
    "test",
    "stage",
    "production",
  ];

  const options = Object.keys(environmentGroups)
    .sort((a, b) => {
      return (
        environments.indexOf(a.split(" ")[0]) -
        environments.indexOf(b.split(" ")[0])
      );
    })
    .reduce((collection, l) => {
      collection.push({
        label: l,
        options: environmentGroups[l].sort((a, b) =>
          a.label.localeCompare(b.label)
        ),
      });

      return collection;
    }, [] as (ITag | { label: string; options: ITag[] })[]);

  const values = captainIds.map((captainId: string) => ({
    value: captainId,
    label:
      siblings?.find((s) => s.captainId === captainId)?.url ??
      `N/A - ${captainId}`,
  }));

  const handleChange = async (vals: ITag[] | ITag | undefined) => {
    if (vals) {
      if (Array.isArray(vals)) {
        onSelect(vals.map((v) => v.value));
      } else {
        onSelect([vals?.value]);
      }
    } else {
      onSelect([]);
    }
  };

  return (
    <Widget
      {...other}
      titleVariant={"subtitle2"}
      title={t(
        `learn.components.learnSelector.${
          multiSelect ? "multi" : "single"
        }.label`
      )}
      titleHelp={t(
        `learn.components.learnSelector.${
          multiSelect ? "multi" : "single"
        }.help`
      )}
      variant={"bordered"}
    >
      <Picker
        clasName={className}
        inputId="picker-demo-multi-creatable-grouped"
        isClearable={false}
        isMulti={multiSelect}
        defaultOptions
        value={!disabled ? values : []}
        onChange={handleChange}
        options={!disabled ? options : []}
        disabled={disabled}
      />
    </Widget>
  );
};
