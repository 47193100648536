import Grid from "@material-ui/core/Grid";
import { FunctionComponent } from "react";
import { GetLearnInstancesWithMetricsByCaptainIdsQuery } from "../../../graphql/types";
import { FileCountsByTypeWidget } from "./widgets/FileCountsByTypeWidget";
import { FileSizesByTypeWidget } from "./widgets/FileSizesByTypeWidget";
import { StorageUsageWidget } from "./widgets/StorageWidget";

export interface LearnStorageSectionProps {
  readonly learns: GetLearnInstancesWithMetricsByCaptainIdsQuery["listLearnInstances"]["items"];
  readonly entitlements?: number;
  readonly stackCharts: boolean;
}

export const LearnStorageSection: FunctionComponent<
  LearnStorageSectionProps
> = ({ learns, stackCharts, entitlements }) => {
  return (
    <Grid container direction={"row"}>
      <StorageUsageWidget
        data-test={"storage-graph"}
        stackCharts={stackCharts}
        learns={learns}
        entitlements={entitlements}
        xs={12}
      />
      <FileSizesByTypeWidget
        data-test={"file-sizes-graph"}
        learns={learns}
        xs={12}
        lg={6}
      />
      <FileCountsByTypeWidget
        data-test={"file-count-graph"}
        learns={learns}
        xs={12}
        lg={6}
      />
    </Grid>
  );
};
