import Grid from "@material-ui/core/Grid";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useSuspendedQuery } from "../../../graphql/GraphqlProvider";
import { getLearnInstanceHealth } from "../../../graphql/queries";
import {
  GetLearnInstanceHealthQuery,
  GetLearnInstanceHealthQueryVariables,
} from "../../../graphql/types";
import { NoHealthMetricsFoundPage } from "../../errorpages";
import { MultiLearnSelector } from "../components/MultiLearnSelector";
import { NewRelicBillboardChart } from "../components/NewRelicChart/NewRelicBillboardChart";
import { NewRelicWidget } from "../components/NewRelicWidget";
import { setCaptainId } from "../learnSlice";
import { useLearn } from "../useLearn";

export const LearnHeath: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const captainId = useAppSelector((s) => s.learn.captainId);
  const { t } = useTranslation();
  const { siblings } = useLearn();

  const newRelicData = useSuspendedQuery<
    GetLearnInstanceHealthQuery,
    GetLearnInstanceHealthQueryVariables
  >(getLearnInstanceHealth, {
    variables: {
      captainId,
    },
  });

  const health = newRelicData.getLearnInstanceHealth;

  return (
    <Grid container spacing={2} direction={"column"}>
      <Grid item md={12} lg={12}>
        <Grid container direction={"row"} spacing={2}>
          <MultiLearnSelector
            captainIds={captainId ? [captainId] : []}
            siblings={siblings}
            onSelect={(ids) => {
              if (ids && ids.length) {
                dispatch(setCaptainId(ids[0]));
              } else {
                dispatch(setCaptainId(undefined));
              }
            }}
            xs={12}
            sm={6}
            md={6}
          />
          <NewRelicBillboardChart
            data-test={"uptime-value-chart"}
            title={t("learn.health.chart.uptime.30Day")}
            titleHelp={t("learn.health.chart.uptime.titleHelp")}
            embeddedChartLink={health.uptimeValueOnlyChartUrl}
          />
          <NewRelicBillboardChart
            data-test={"apdex-value-chart"}
            title={t("learn.health.chart.apdex.30Day")}
            titleHelp={t("learn.health.chart.apdex.titleHelp")}
            embeddedChartLink={health.apdexValueOnlyChartUrl}
          />
        </Grid>
      </Grid>
      {!health.apdexChartChartUrl ? (
        <NoHealthMetricsFoundPage />
      ) : (
        <Grid item>
          <Grid container direction={"row"} justifyContent={"flex-start"}>
            <NewRelicWidget
              data-test={"uptime-chart"}
              title={t("learn.health.chart.uptime.title")}
              titleHelp={t("learn.health.chart.uptime.titleHelp")}
              url={health.uptimeChartUrl}
            />
            <NewRelicWidget
              data-test={"apdex-chart"}
              title={t("learn.health.chart.apdex.title")}
              titleHelp={t("learn.health.chart.apdex.titleHelp")}
              url={health.apdexChartChartUrl}
            />
            <NewRelicWidget
              data-test={"throughput-chart"}
              title={t("learn.health.chart.throughput.title")}
              titleHelp={t("learn.health.chart.throughput.titleHelp")}
              url={health.throughputChartUrl}
            />
            <NewRelicWidget
              data-test={"unique-user-chart"}
              title={t("learn.health.chart.uniqueUsers.title")}
              titleHelp={t("learn.health.chart.uniqueUsers.titleHelp")}
              url={health.uniqueUserIdChartUrl}
            />
            <NewRelicWidget
              data-test={"login-pageview-chart"}
              title={t("learn.health.chart.loginPageViews.title")}
              titleHelp={t("learn.health.chart.loginPageViews.titleHelp")}
              url={health.loginPageViewChartUrl}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
