import numeral from "numeral";

export const formatNumber = (valueFormatString: string, size?: number) => {
  if (size === -1) {
    return "unlimited";
  }

  // We replace i with "" as we're technically using gigibytes, not gigabytes
  return numeral(size ?? 0)
    .format(valueFormatString)
    .replace("i", "");
};
