import { i18n } from "@bb-ui/i18n/dist";
import { CircleFilled } from "@bb-ui/icons/dist/medium";
import { makeStyles } from "@bb-ui/react-library";
import { Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import {
  toDateSameLocal,
  toLocalMonthDayString,
  toLocalMonthYearString,
} from "../../../../time/time-parser";
import "@js-joda/timezone";

export interface LearnMetricsChartTooltipProps {
  active?: boolean;
  payload?: { name: string; value: number; fill: string }[];
  label?: string;
  value?: string;
  formatter: (value: number, index?: number) => string;
  entitlement?: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.b1,
    opacity: 0.9,
    borderRadius: 5,
    padding: theme.spacing(2),
  },
  legendIcon: {
    paddingRight: theme.spacing(1),
  },
  entry: {},
  entryNameContainer: {
    marginRight: theme.spacing(3),
  },
}));

export const LearnMetricsChartTooltipEntry: FunctionComponent<{
  value: string;
  title: string;
  fill?: string;
}> = ({ fill, title, value }) => {
  const classes = useStyles();

  return (
    <Grid item className={classes.entry}>
      <Grid
        container
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid item className={classes.entryNameContainer}>
          <Grid container direction={"row"} alignItems={"center"}>
            {(() =>
              fill && (
                <CircleFilled
                  style={{ color: fill }}
                  className={classes.legendIcon}
                />
              ))()}
            <Typography variant={"subtitle1"}>{title}</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant={"subtitle2"}>{value}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const LearnMetricsChartTooltip: FunctionComponent<
  LearnMetricsChartTooltipProps
> = ({ active, entitlement, label, payload, formatter }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (active && payload && payload.length) {
    const total = payload.reduce((c, m) => c + m.value, 0);

    return (
      <Grid container direction={"column"} spacing={1} className={classes.root}>
        <Grid item>
          <Typography variant={"h3"}>
            {entitlement !== undefined
              ? toLocalMonthYearString(
                  toDateSameLocal(label),
                  i18n.i18n.language
                )
              : toLocalMonthDayString(
                  toDateSameLocal(label),
                  i18n.i18n.language
                )}
          </Typography>
        </Grid>
        {(() =>
          payload.map((metric, index) => (
            <LearnMetricsChartTooltipEntry
              value={formatter(metric.value, index)}
              title={metric.name}
            />
          )))()}
        <Divider />
        <LearnMetricsChartTooltipEntry
          value={formatter(total)}
          title={"Total"}
        />
        {(() =>
          entitlement !== undefined && (
            <>
              <LearnMetricsChartTooltipEntry
                value={formatter(entitlement)}
                title={t("learn.metrics.chart.tooltip.entitlement")}
              />
              <LearnMetricsChartTooltipEntry
                value={formatter(entitlement - total)}
                title={t("learn.metrics.chart.tooltip.available")}
              />
            </>
          ))()}
      </Grid>
    );
  }

  return null;
};
