import { gql } from "@apollo/client";
import { i18n } from "@bb-ui/i18n/dist";
import {
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@bb-ui/react-library";
import { Switch } from "@bb-ui/react-library/dist/components/Switch";
import { TableCell, TableContainer, TablePagination } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ErrorMessage } from "../../../components/ErrorMessage/ErrorMessage";
import { HelpTooltip } from "../../../components/HelpTooltip/HelpTooltip";
import { CircularLoadingSuspense } from "../../../components/Loading/CircularLoadingSuspense";
import { Widget } from "../../../components/Widget/Widget";
import { useSuspendedQuery } from "../../../graphql/GraphqlProvider";
import { getLearnInstancesWithMetricsByCaptainIds } from "../../../graphql/queries";
import {
  EntitlementsQuery,
  GetLearnInstancesWithMetricsByCaptainIdsQuery,
  GetLearnInstancesWithMetricsByCaptainIdsQueryVariables,
} from "../../../graphql/types";
import { toDateSameLocal, toLocalDateString } from "../../../time/time-parser";
import { formatNumber } from "../../../util/numbers";
import { ErrorBoundary } from "../../errorpages/ErrorBoundary";
import { UnknownErrorPage } from "../../errorpages/UnknownErrorPage";
import { DownloadPDF } from "../components/DownloadPDF";
import { MultiLearnSelector } from "../components/MultiLearnSelector";
import { StorageUsageWidget } from "../LearnMetrics/widgets/StorageWidget";
import {
  setCaptainId,
  setEntitlementsChartShowAllLearns,
  setEntitlementsChartShowEntitlementLine,
} from "../learnSlice";
import { useLearn } from "../useLearn";

const useStyles = makeStyles(() => ({
  legend: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    maxWidth: "100%",
    "& > li": {
      width: "inherit",
    },
  },
}));

export const LearnEntitlements: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const captainId = searchParams.get("captainId")
    ? searchParams.get("captainId")
    : useAppSelector((s) => s.learn.captainId);
  const { siblings } = useLearn();
  const { t } = useTranslation();
  const classes = useStyles();
  const showEntitlementLine = useAppSelector(
    (s) => s.learn.entitlements?.controls?.showEntitlementLine
  );
  const showAllLearns = useAppSelector(
    (s) => s.learn.entitlements?.controls?.showAllLearns
  );

  let captainIds: string[];

  let prodSiblings = (siblings ?? []).filter((l) =>
    l.environment.includes("prod")
  );

  let isLocal = (siblings ?? []).filter((l) =>
    l.name.includes("Blackboard Engineering Operations")
  );

  if (!prodSiblings.length) {
    prodSiblings = siblings;
  }

  if (showAllLearns && !isLocal.length) {
    captainIds = prodSiblings.map((l) => l.captainId);
  } else {
    captainIds = [captainId];
  }

  return (
    <Grid container spacing={2} direction={"column"}>
      <Grid item md={12} lg={12}>
        <Grid
          container
          direction={"row"}
          spacing={2}
          justifyContent={"flex-end"}
        >
          <MultiLearnSelector
            captainIds={captainIds}
            siblings={siblings}
            disabled={showAllLearns}
            prodOnly={true}
            onSelect={(ids) => {
              if (ids && ids.length) {
                dispatch(setCaptainId(ids[0]));
              } else {
                dispatch(setCaptainId(undefined));
              }
            }}
            xs={12}
            sm={8}
            md={8}
          />
          <Widget
            title={t("learn.metrics.chart.sections.controls.title")}
            titleHelp={t("learn.metrics.chart.sections.controls.help")}
            variant={"bordered"}
            titleVariant={"subtitle2"}
            md={4}
            sm={4}
            xs={12}
          >
            <Grid container className={classes.legend}>
              <HelpTooltip
                help={t(
                  "learn.metrics.chart.sections.controls.showEntitlementLine.help"
                )}
                icon={false}
              >
                <FormControlLabel
                  value={showEntitlementLine}
                  htmlFor="stackDataSwitch"
                  control={
                    <Switch
                      id="stackDataSwitch"
                      checked={showEntitlementLine}
                      onChange={(evt) =>
                        dispatch(
                          setEntitlementsChartShowEntitlementLine(
                            evt.target.checked
                          )
                        )
                      }
                    />
                  }
                  label={t(
                    "learn.metrics.chart.sections.controls.showEntitlementLine.label"
                  )}
                />
              </HelpTooltip>
              <HelpTooltip
                help={t(
                  "learn.metrics.chart.sections.controls.showAllLearns.help"
                )}
                icon={false}
              >
                <FormControlLabel
                  value={showAllLearns}
                  htmlFor="showAllLearnsSwitch"
                  control={
                    <Switch
                      id="showAllLearnsSwitch"
                      checked={showAllLearns}
                      onChange={(evt) =>
                        dispatch(
                          setEntitlementsChartShowAllLearns(evt.target.checked)
                        )
                      }
                    />
                  }
                  label={t(
                    "learn.metrics.chart.sections.controls.showAllLearns.label"
                  )}
                />
              </HelpTooltip>
              <DownloadPDF name={"entitlements"} />
            </Grid>
          </Widget>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <ErrorBoundary fallback={<UnknownErrorPage />}>
          <CircularLoadingSuspense>
            <ListEntitlementsHomePageContents
              captainId={captainId}
              captainIds={captainIds}
              showEntitlementLine={showEntitlementLine}
            />
          </CircularLoadingSuspense>
        </ErrorBoundary>
      </Grid>
    </Grid>
  );
};

export const ListEntitlementsHomePageContents: FunctionComponent<{
  captainId: string;
  captainIds: string[];
  showEntitlementLine: boolean;
}> = ({ captainId, captainIds, showEntitlementLine }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const handleChangePage = (_: any, p: number) => {
    setPage(p);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
  };
  if (!captainId) {
    return (
      <ErrorMessage
        title={"Missing Parameter"}
        message={"You must first select a Learn instance to view"}
      />
    );
  }
  const data = useSuspendedQuery<
    GetLearnInstancesWithMetricsByCaptainIdsQuery,
    GetLearnInstancesWithMetricsByCaptainIdsQueryVariables
  >(getLearnInstancesWithMetricsByCaptainIds, {
    variables: {
      captainIds,
      limit: 12,
    },
  });
  const { clientEntitlements } = useSuspendedQuery<
    EntitlementsQuery,
    {
      limit?: number;
      offset?: number;
      captainId: string;
    }
  >(
    gql`
      query getEntitlements($captainId: String, $limit: Int, $offset: Int) {
        clientEntitlements(
          filter: { captainId: $captainId, limit: $limit, offset: $offset }
        ) {
          total
          totalUsers
          totalStorage
          items {
            start
            end
            status
            storageEntitlement
            userEntitlement
            contractNumber
          }
        }
      }
    `,
    {
      variables: {
        captainId: captainId,
        limit: pageSize,
        offset: page * pageSize,
      },
    }
  );

  const learns = data?.listLearnInstances?.items;

  return (
    <Grid container>
      <StorageUsageWidget
        data-test={"storage-entitlements"}
        stackCharts={true}
        learns={learns}
        showEntitlementLine={showEntitlementLine}
        showEntitlements={true}
        entitlements={clientEntitlements.totalStorage}
        xs={12}
      />
      {clientEntitlements.items?.length > 0 && (
        <Widget
          title={t("learn.entitlements.table.title")}
          titleHelp={t("learn.entitlements.table.help")}
          xs={12}
        >
          <TableContainer component={Paper} data-test="entitlements-table">
            <Table size={"small"}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t("learn.entitlements.table.headers.contract")}
                  </TableCell>
                  <TableCell>
                    {t("learn.entitlements.table.headers.startDate")}
                  </TableCell>
                  <TableCell>
                    {t("learn.entitlements.table.headers.endDate")}
                  </TableCell>
                  <TableCell>
                    {t("learn.entitlements.table.headers.status")}
                  </TableCell>
                  <TableCell>
                    {t("learn.entitlements.table.headers.storage")}
                  </TableCell>
                  <TableCell>
                    {t("learn.entitlements.table.headers.users")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clientEntitlements.items.map((obj, index) => (
                  <TableRow key={`entitlement-table-${index}`}>
                    <TableCell>{obj.contractNumber}</TableCell>
                    <TableCell>
                      {toLocalDateString(
                        toDateSameLocal(obj.start),
                        i18n.i18n.language
                      )}
                    </TableCell>
                    <TableCell>
                      {toLocalDateString(
                        toDateSameLocal(obj.end),
                        i18n.i18n.language
                      )}
                    </TableCell>
                    <TableCell>{obj.status}</TableCell>
                    <TableCell>
                      {formatNumber(
                        t("learn.metrics.chart.storage.valueFormatString"),
                        obj.storageEntitlement * 1000 * 1000 * 1000
                      )}
                    </TableCell>
                    <TableCell>{obj.userEntitlement}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[3, 10, 25]}
            component="div"
            count={clientEntitlements.total}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Widget>
      )}
    </Grid>
  );
};
