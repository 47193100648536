import { GridProps, makeStyles, TypographyVariant } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import React, { FunctionComponent } from "react";
import { HelpTooltip } from "../HelpTooltip/HelpTooltip";

export interface CustomGrid extends Omit<GridProps, "title"> {}

export interface WidgetProps extends CustomGrid {
  readonly title: React.ReactNode;
  readonly titleHelp: string;
  readonly titleVariant?: TypographyVariant;
  readonly variant?: "borderless" | "bordered";
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  wrapper: {
    padding: theme.spacing(2),
  },
  bordered: {
    borderColor: theme.palette.background.b3,
    borderSize: 1,
    borderStyle: "solid",
    backgroundColor: theme.palette.background.b1,
  },
}));

export const Widget: FunctionComponent<WidgetProps & GridProps> = ({
  children,
  title,
  titleHelp,
  titleVariant,
  variant,
  ...other
}) => {
  const classes = useStyles();

  return (
    <Grid item {...other} className={clsx(other.className)}>
      <Grid
        item
        xs={12}
        container
        alignContent={"flex-start"}
        spacing={2}
        className={clsx(
          classes.wrapper,
          variant === "bordered" ? classes.bordered : undefined
        )}
      >
        <Grid item>
          <HelpTooltip help={titleHelp} className={classes.title}>
            <Typography variant={titleVariant ?? "h1"}>{title}</Typography>
          </HelpTooltip>
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};
