import {
  Divider,
  ListItemText,
  makeStyles,
  NavigationList,
  NavigationListItem,
  NavigationListItemProps,
  NavigationListScrollWrapper,
  Typography,
} from "@bb-ui/react-library";
import { ForwardRefExoticComponent, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useMatch } from "react-router";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export const LearnPage: FunctionComponent = () => {
  const classes = useStyles();
  const match = useMatch("/learn/:page/*");
  const { t } = useTranslation();

  const NavigationListItemAugment: ForwardRefExoticComponent<
    Omit<NavigationListItemProps, "href"> & { to: string }
  > = NavigationListItem as any;

  return (
    <>
      <NavigationListScrollWrapper
        scrollLeftButtonAriaLabel="Previous navigation links"
        scrollRightButtonAriaLabel="Next navigation links"
      >
        <NavigationList navTagAriaLabel="menu" orientation="horizontal">
          <NavigationListItemAugment
            component={Link}
            to={`/learn/sites`}
            active={match?.params.page === "sites"}
          >
            <ListItemText>
              <Typography component="span" variant="subtitle2">
                {t("learn.navigation.sites")}
              </Typography>
            </ListItemText>
          </NavigationListItemAugment>
          <NavigationListItemAugment
            component={Link}
            to={`/learn/insights/storage`}
            data-test={"insights"}
            active={match?.params.page === "insights"}
          >
            <ListItemText>
              <Typography component="span" variant="subtitle2">
                {t("learn.navigation.insights")}
              </Typography>
            </ListItemText>
          </NavigationListItemAugment>
          <NavigationListItemAugment
            component={Link}
            to={`/learn/entitlements`}
            data-test={"entitlements"}
            active={match?.params.page === "entitlements"}
          >
            <ListItemText>
              <Typography component="span" variant="subtitle2">
                {t("learn.navigation.entitlements")}
              </Typography>
            </ListItemText>
          </NavigationListItemAugment>
          <NavigationListItemAugment
            component={Link}
            to={`/learn/health`}
            data-test={"health"}
            active={match?.params.page === "health"}
          >
            <ListItemText>
              <Typography component="span" variant="subtitle2">
                {t("learn.navigation.health")}
              </Typography>
            </ListItemText>
          </NavigationListItemAugment>
          <NavigationListItemAugment
            component={Link}
            to={`/learn/support-request`}
            data-test={"support-request"}
            active={match?.params.page === "support-request"}
          >
            <ListItemText>
              <Typography component="span" variant="subtitle2">
                {t("learn.navigation.supportRequestBreakdown")}
              </Typography>
            </ListItemText>
          </NavigationListItemAugment>
        </NavigationList>
      </NavigationListScrollWrapper>
      <div className={classes.contentWrapper}>
        <Divider />
        <Outlet />
      </div>
    </>
  );
};
