import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { AccessibleImage } from "../../components/AccessibleImage";
import { ErrorPage } from "./components/ErrorPage";
import error from "./error.svg";

export interface AuthenticationErrorProps {
  readonly message: string;
}

export const AuthenticationError: FunctionComponent<
  AuthenticationErrorProps
> = ({ message }) => {
  const { t } = useTranslation();

  return (
    <ErrorPage
      image={
        <AccessibleImage src={error} alt={t("errorpages.accessDenied.title")} />
      }
      title={t("errorpages.auth.title")}
      message={message}
    />
  );
};
