import { Download } from "@bb-ui/icons/dist/small";
import { DateTimeFormatter, LocalDate } from "@js-joda/core";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { HelpTooltip } from "../../../../components/HelpTooltip/HelpTooltip";
import { ToolButton } from "../ToolButton";

export interface DownloadPDFProps {
  readonly name: string;
}

export const DownloadPDF: FunctionComponent<DownloadPDFProps> = ({ name }) => {
  const { t } = useTranslation();
  function handleDownloadPdf() {
    let divHeight = document.body.offsetHeight;
    let divWidth = document.body.offsetWidth;
    let ratio = divHeight / divWidth;

    void html2canvas(document.body).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm");
      let width = pdf.internal.pageSize.getWidth();
      let height = ratio * width;
      pdf.addImage(imgData, "JPEG", 0, 0, width, height);
      pdf.save(
        `${LocalDate.now().format(
          DateTimeFormatter.ofPattern("yyyy-MM-dd")
        )}-${name}.pdf`
      );
    });
  }

  return (
    <HelpTooltip
      help={t("learn.metrics.chart.sections.controls.downloadPdf.help")}
      icon={false}
    >
      <ToolButton
        data-test="pdf-button"
        color={"primary"}
        onClick={() => {
          handleDownloadPdf();
        }}
        startIcon={<Download />}
      >
        {t("learn.metrics.chart.sections.controls.downloadPdf.label")}
      </ToolButton>
    </HelpTooltip>
  );
};
