import { FunctionComponent, ReactNode, Suspense } from "react";
import { ErrorPage } from "../../features/errorpages/components/ErrorPage";
import { LoadingIndicator } from "./LoadingIndicator";

export interface MessageLoadingSuspenseProps {
  readonly children: ReactNode;
  readonly title?: string;
  readonly message?: string;
}

export const MessageLoadingSuspense: FunctionComponent<
  MessageLoadingSuspenseProps
> = ({ children, message, title }) => {
  return (
    <Suspense
      fallback={
        <ErrorPage
          title={title}
          message={message}
          image={<LoadingIndicator />}
        />
      }
    >
      {children}
    </Suspense>
  );
};
