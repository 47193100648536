import { makeStyles } from "@bb-ui/react-library";
import Grid from "@material-ui/core/Grid";
import { FunctionComponent } from "react";
import { Widget } from "../../../../components/Widget/Widget";
import { FailedToFetchPage } from "../../../errorpages/FailedToFetchPage";

export interface NewRelicChartProps {
  title: string;
  titleHelp: string;
  embeddedChartLink: string | null | undefined;
}

const useStyles = makeStyles(() => ({
  iframe: {
    height: 500,
    width: "100%",
    border: "0",
    minHeight: 515,
    overflow: "visible",
  },
}));

export const NewRelicChart: FunctionComponent<NewRelicChartProps> = ({
  title,
  titleHelp,
  embeddedChartLink,
  ...props
}) => {
  const classes = useStyles();
  let url = undefined;
  if (embeddedChartLink != null && embeddedChartLink.startsWith("https")) {
    url = new URL(embeddedChartLink);
  }

  return (
    <Widget xs={6} title={title} titleHelp={titleHelp} {...props}>
      {!embeddedChartLink || embeddedChartLink.startsWith("failed") ? (
        <FailedToFetchPage />
      ) : (
        <Grid container direction={"column"}>
          <iframe className={classes.iframe} src={url ? url.toString() : ""} />
        </Grid>
      )}
    </Widget>
  );
};
