import { i18n } from "@bb-ui/i18n/dist";
import { CircleFilled } from "@bb-ui/icons/dist/medium";
import { List, ListItem } from "@bb-ui/react-library";
import { purple } from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { getColorForIndex } from "../../../../../app/colors";
import { useAppSelector } from "../../../../../app/hooks";
import { ChartLastUpdated } from "../../../../../components/ChartLastUpdated";
import { CustomGrid, Widget } from "../../../../../components/Widget/Widget";
import {
  formatSameDate,
  toDate,
  toLocalString,
} from "../../../../../time/time-parser";
import { formatNumber } from "../../../../../util/numbers";
import { CSVDownload, CSVRow } from "../../../components/CSV/CSVDownload";
import { LearnMetricsChartTooltip } from "../../../components/LearnMetricsChart/LearnMetricsChartTooltip";
import { ResolutionSpan, selectTimeResolution } from "../../../learnSlice";

export interface CourseData {
  date: string;
  ultraCourseCountStatusClassic: number;
  ultraCourseCountStatusPreview: number;
  ultraCourseCountStatusUltra: number;
  ultraCourseCountStatusUndecided: number;
}

export interface HistoricalCoursesProperties extends CustomGrid {
  readonly data: {
    captainId: string;
    url: string;
    lastUpdated: string;
    metrics: {
      courses: {
        metrics: CourseData[];
      };
    };
  }[];
}
const addCourseData = (a: CourseData, b?: CourseData) => ({
  date: a.date,
  ultraCourseCountStatusClassic:
    (b?.ultraCourseCountStatusClassic ?? 0) + a.ultraCourseCountStatusClassic,
  ultraCourseCountStatusPreview:
    (b?.ultraCourseCountStatusPreview ?? 0) + a.ultraCourseCountStatusPreview,
  ultraCourseCountStatusUltra:
    (b?.ultraCourseCountStatusUltra ?? 0) + a.ultraCourseCountStatusUltra,
  ultraCourseCountStatusUndecided:
    (b?.ultraCourseCountStatusUndecided ?? 0) +
    a.ultraCourseCountStatusUndecided,
});
const useStyles = makeStyles((theme) => ({
  legend: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    maxWidth: "50%",
    "& > li": {
      width: "inherit",
    },
  },
  legendIcon: {
    paddingRight: theme.spacing(1),
  },
  infoTable: {
    borderLeftStyle: "solid",
    borderLeftColor: purple[500],
    borderLeftSize: 4,
  },
  graph: {
    marginTop: theme.spacing(4),
    minHeight: 300,
    height: "100%",
    width: "100%",
  },
  messages: {
    minHeight: 16,
    fontSize: 14,
    color: theme.palette.error.main,
  },
}));
export const HistoricalCourses: FunctionComponent<
  HistoricalCoursesProperties
> = ({ data, ...other }) => {
  const { t } = useTranslation();
  const chartResolution = useAppSelector(selectTimeResolution);
  const classes = useStyles();
  const stackCharts = useAppSelector(
    (s) => s.learn.metrics?.controls?.stacking
  );
  const chartData = Object.values(
    data
      .flatMap((learn) => learn.metrics.courses.metrics)
      .reduce((acc, x) => {
        acc[x.date] = addCourseData(x, acc[x.date]);
        return acc;
      }, {} as { [key: string]: CourseData })
  ).sort((a, b) => toDate(a.date).getTime() - toDate(b.date).getTime());
  const lastUpdated = toLocalString(
    data
      .map((learn) => toDate(learn.lastUpdated))
      .reduce((a, b) => (a > b ? a : b), new Date(0)),
    i18n.i18n.language
  );
  const CoursesTypes = [
    "ultraCourseCountStatusClassic",
    "ultraCourseCountStatusPreview",
    "ultraCourseCountStatusUltra",
    "ultraCourseCountStatusUndecided",
  ];
  const valueFormatString = t("learn.metrics.chart.courses.valueFormatString");
  return (
    <Widget
      data-test="chart-courses-historic"
      title={t("learn.metrics.chart.courses.title")}
      titleHelp={t("learn.metrics.chart.courses.help")}
      {...other}
      xs={12}
    >
      <Grid container item xs={12} direction={"row"} spacing={1}>
        {(() =>
          CoursesTypes.map((key, i) => {
            return (
              <Grid item className={classes.legend} key={`point-${i}`}>
                <CircleFilled
                  style={{ color: getColorForIndex(i) }}
                  className={classes.legendIcon}
                />
                {t(`learn.metrics.chart.courses.labels.${key}`)}
              </Grid>
            );
          }))()}
      </Grid>
      <Grid
        container
        item
        xs={9}
        className={classes.infoTable}
        spacing={1}
        justifyContent="space-between"
      >
        <Grid item xs>
          <CSVDownload
            data-test={`courses-csv-button`}
            prefixName={"courses"}
            read={async () =>
              chartData.map((obj) => {
                const row: CSVRow = {};
                row[t("csv.labels.date")] = obj.date;
                row[
                  t(
                    `learn.metrics.chart.courses.labels.ultraCourseCountStatusClassic`
                  )
                ] = obj.ultraCourseCountStatusClassic;
                row[
                  t(
                    `learn.metrics.chart.courses.labels.ultraCourseCountStatusPreview`
                  )
                ] = obj.ultraCourseCountStatusPreview;
                row[
                  t(
                    `learn.metrics.chart.courses.labels.ultraCourseCountStatusUltra`
                  )
                ] = obj.ultraCourseCountStatusUltra;
                row[
                  t(
                    `learn.metrics.chart.courses.labels.ultraCourseCountStatusUndecided`
                  )
                ] = obj.ultraCourseCountStatusUndecided;
                return row;
              })
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ResponsiveContainer minHeight={300} debounce={0.5}>
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              width={500}
              height={400}
              data={chartData}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                tickFormatter={(timeStr) => {
                  return chartResolution == ResolutionSpan.Daily
                    ? formatSameDate(timeStr, "MM/dd")
                    : formatSameDate(timeStr, "MM/yyyy");
                }}
              />
              <YAxis
                tickFormatter={(val) => formatNumber(valueFormatString, val)}
              />
              <Tooltip
                content={
                  <LearnMetricsChartTooltip
                    formatter={(value) =>
                      formatNumber(valueFormatString, value)
                    }
                  />
                }
              />
              {CoursesTypes.map((key, index) => (
                <Area
                  dataKey={key}
                  dot={true}
                  name={t(`learn.metrics.chart.courses.labels.${key}`)}
                  stroke={getColorForIndex(index)}
                  fill={getColorForIndex(index)}
                  strokeWidth={3}
                  strokeOpacity={0.7}
                  fillOpacity={0.3}
                  stackId={stackCharts ? "courses" : undefined}
                />
              ))}
            </AreaChart>
          </ResponsiveContainer>
        </ResponsiveContainer>
      </Grid>
      <Grid container direction={"column"} alignContent={"flex-end"}>
        <Grid item>
          <List dense>
            <ListItem>
              <ChartLastUpdated lastUpdated={lastUpdated} />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Widget>
  );
};
