import { i18n } from "@bb-ui/i18n/dist";
import { BbThemeProvider } from "@bb-ui/react-library";
import { CssBaseline } from "@material-ui/core";
import { FunctionComponent } from "react";
import { Navigate } from "react-router";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useAppSelector } from "./app/hooks";
import { CircularLoadingSuspense } from "./components/Loading/CircularLoadingSuspense";
import { MessageLoadingSuspense } from "./components/Loading/LoadingMessageSuspense";
import { AuthGate } from "./features/auth/AuthGate";
import { AuthProvider } from "./features/auth/AuthProvider";
import { PageNotFoundErrorPage } from "./features/errorpages";
import { ErrorBoundary } from "./features/errorpages/ErrorBoundary";
import { UnknownErrorPage } from "./features/errorpages/UnknownErrorPage";
import { ClientSupportBreakDown } from "./features/learn/ClientSupport/ClientSupportBreakDown";
import { LearnEntitlements } from "./features/learn/LearnEntitlements";
import { LearnHeath } from "./features/learn/LearnHealth/LearnHealth";
import { LearnInsightsHomePage } from "./features/learn/LearnMetrics/LearnInsightsHomePage";
import { LearnPage } from "./features/learn/LearnPage";
import { LearnSitesHomePage } from "./features/learn/LearnSites/LearnSitesHomePage";
import { LearnProvider } from "./features/learn/useLearn";
import { LocationHashProvider } from "./features/LocationHash/LocationHashProvider";
import { APIProvider } from "./graphql/GraphqlProvider";
import { Layout } from "./layout/Layout";

i18n.init({
  fallbackLng: "en",
  whitelist: [
    "ar",
    "ar-SA",
    "cs-CZ",
    "cy-GB",
    "da-DK",
    "de",
    "en",
    "en-AU",
    "en-GB",
    "en-US-k12",
    "en-us-pro",
    "es",
    "fr",
    "fr-CA",
    "he-IL",
    "it",
    "ja",
    "ko",
    "ms-MY",
    "nb-NO",
    "nl",
    "nn-NO",
    "pl",
    "pt",
    "pt-BR",
    "ru",
    "sv",
    "th",
    "tr",
    "zh-CN",
    "zh-TW",
  ],
});

export const App: FunctionComponent = () => {
  const theme = useAppSelector((s) => s.app.theme) ?? "dark";

  return (
    <BrowserRouter>
      <LocationHashProvider>
        <CircularLoadingSuspense>
          <ErrorBoundary fallback={<UnknownErrorPage />}>
            <BbThemeProvider theme={theme}>
              <CssBaseline />
              <AuthProvider>
                <APIProvider>
                  <AuthGate>
                    <AppContents />
                  </AuthGate>
                </APIProvider>
              </AuthProvider>
            </BbThemeProvider>
          </ErrorBoundary>
        </CircularLoadingSuspense>
      </LocationHashProvider>
    </BrowserRouter>
  );
};

export const AppContents: FunctionComponent = () => {
  return (
    <MessageLoadingSuspense>
      <LearnProvider>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Navigate replace to="learn/sites" />} />
            <Route path="learn" element={<LearnPage />}>
              <Route path="sites" element={<LearnSitesHomePage />} />
              <Route path="insights/*" element={<LearnInsightsHomePage />} />
              <Route path="health" element={<LearnHeath />} />
              <Route path="entitlements" element={<LearnEntitlements />} />
              <Route
                path="support-request"
                element={<ClientSupportBreakDown />}
              />
            </Route>
          </Route>
          <Route path="*" element={<PageNotFoundErrorPage />} />
        </Routes>
      </LearnProvider>
    </MessageLoadingSuspense>
  );
};

export default App;
