import React, { FunctionComponent } from "react";
import { useLocation } from "react-router";

export const LocationHashContext = React.createContext<{
  hash: string;
  setHash: (hash: string | Record<string, string>) => void;
  setKV: (key: string, value: string | undefined) => void;
  params: URLSearchParams;
}>({
  hash: "",
  setHash: () => {},
  setKV: () => {},
  params: new URLSearchParams(),
});

export const useHash = () => {
  return React.useContext(LocationHashContext);
};

export const LocationHashProvider: FunctionComponent = ({ children }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.hash.substring(1));

  const setHash = React.useCallback(
    (hash: string | Record<string, string>) => {
      if (typeof hash === "object") {
        window.location.hash = new URLSearchParams(hash).toString();
      } else {
        window.location.hash = hash;
      }
    },
    [location]
  );

  const setKV = React.useCallback(
    (key: string, value: string | undefined) => {
      if (value === undefined) {
        params.delete(key);
      } else {
        params.set(key, value);
      }

      window.location.hash = "#" + params.toString();
    },
    [location]
  );

  return (
    <LocationHashContext.Provider
      value={{ setHash, setKV, hash: location.hash, params }}
    >
      {children}
    </LocationHashContext.Provider>
  );
};
