import "@js-joda/timezone";
import { ZonedDateTime } from "@js-joda/core";
import axios from "axios";
import jwtDecode, { JwtPayload } from "jwt-decode";
import React, { FunctionComponent, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router";
import { LoadingIndicator } from "../../components/Loading/LoadingIndicator";
import { LtiError } from "../errorpages/LtiError";
import { AuthContext } from "./UserProvider";

export const LTIProvider: FunctionComponent = ({ children }) => {
  return (
    <Routes>
      {/* CloudFront captures all paths under lti/* so this should never be hit */}
      <Route path="lti/*" element={<Navigate to="/" />} />
      <Route path="launch/error" element={<LtiError />} />
      <Route path="*" element={<LTIHandler>{children}</LTIHandler>} />
    </Routes>
  );
};

interface PortalToken extends JwtPayload {
  [key: string]: any;
}

export const LTIHandler: FunctionComponent = ({ children }) => {
  const [expired, setExpired] = React.useState(false);
  const [ttlExpired, setTtlExpired] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [token, setToken] = React.useState("");
  const [tenantId, setTenantId] = React.useState("");

  const pullToken = async () => {
    try {
      const jwt = await axios.get<{ jwt: string }>("/lti/token");
      setToken(jwt.data.jwt);
    } catch (e) {
      console.error("error pulling jwt");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void pullToken();
  }, []);

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode<PortalToken>(token);
      if (decoded) {
        setTenantId(decoded.tenantId["tenant-id"]);
        const expiresAt = decoded.exp;
        if (expiresAt) {
          const ttl = (expiresAt - ZonedDateTime.now().toEpochSecond()) * 1000;
          if (!expired) {
            const timeout = setTimeout(() => {
              setExpired(true);
            }, ttl);
            return () => clearTimeout(timeout);
          }
        }
      }
    }
    return;
  }, [token]);
  React.useEffect(() => {
    let timeLeft = 30 * 60 * 1000; // 30 minutes in milliseconds;

    if (!ttlExpired) {
      const ttlTimout = setTimeout(() => {
        setTtlExpired(true);
      }, timeLeft);

      return () => clearTimeout(ttlTimout);
    }

    return () => {};
  }, [ttlExpired, setTtlExpired]);

  const signOut = () => {
    setTenantId("");
    setToken("");
    setTtlExpired(true);
  };
  return (
    <AuthContext.Provider
      value={{
        token,
        tenantId,
        expired,
        ttlExpired,
        signOut,
      }}
    >
      {loading ? <LoadingIndicator /> : children}
    </AuthContext.Provider>
  );
};
