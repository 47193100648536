import { BbThemeProvider } from "@bb-ui/react-library/dist/components/BbThemeProvider";
import { CircularProgress } from "@bb-ui/react-library/dist/components/Progress";
import { getColor } from "@bb-ui/react-library/dist/components/styles";
import { createStyles, makeStyles } from "@material-ui/core";

import Fade from "@material-ui/core/Fade";
import clsx from "clsx";
import * as React from "react";
import { useAppSelector } from "../../app/hooks";

export interface LoadingIndicatorProps {
  isLoading?: boolean;
  theme?: "light" | "dark";
  variant?: "fill-window" | "fill-content";
  size?: "small" | "medium" | "large";
  className?: string;
}

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      display: "grid",
      justifyContent: "center",
      alignContent: "center",
    },
    fillWindow: (props: LoadingIndicatorProps) => ({
      position: "fixed" as "fixed",
      zIndex: 99999,
      backgroundColor: getColor("background.default", props.theme || "light"),
    }),
    loadingIndicator: (props: LoadingIndicatorProps) => ({
      color: getColor("brand.main", props.theme || "light"),
    }),
  })
);

export const LoadingIndicator: React.FunctionComponent<
  LoadingIndicatorProps
> = (props) => {
  const classes = useStyles(props);
  const theme = useAppSelector((s) => s.app.theme);
  const {
    isLoading,
    variant,
    size,
    className: consumerClassName,
    ...other
  } = props;

  return (
    <Fade
      mountOnEnter
      unmountOnExit
      in={isLoading}
      timeout={{ enter: 0, exit: 200 }}
    >
      <BbThemeProvider theme={theme || "light"}>
        <div
          className={clsx(
            classes.root,
            {
              [classes.fillWindow]: variant === "fill-window",
            },
            consumerClassName
          )}
          data-testid="loading-indicator"
          {...other}
        >
          <CircularProgress
            id={""}
            label={""}
            size={size}
            className={classes.loadingIndicator}
          />
        </div>
      </BbThemeProvider>
    </Fade>
  );
};

LoadingIndicator.defaultProps = {
  isLoading: true,
};
