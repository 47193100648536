import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { CustomGrid } from "../../../../../components/Widget/Widget";
import { GetLearnInstancesWithMetricsByCaptainIdsQuery } from "../../../../../graphql/types";
import { LearnMetricsChart } from "../../../components/LearnMetricsChart";

export interface UniqueUserLogins90DayWidgetProps extends CustomGrid {
  readonly learns: GetLearnInstancesWithMetricsByCaptainIdsQuery["listLearnInstances"]["items"];
  readonly stackCharts: boolean;
}

export const UniqueUserLogins90DayWidget: FunctionComponent<
  UniqueUserLogins90DayWidgetProps
> = ({ learns, ...props }) => {
  const { t } = useTranslation();

  const metrics = learns.map((l) => ({
    learn: l,
    metrics: l.metrics.logins.metrics.map((m) => ({
      date: m.date,
      captainId: l.captainId,
      title: l.url,
      total: m.uniqueUserLogins90Days,
    })),
  }));

  const today = learns.reduce(
    (a, b) =>
      a +
      b.metrics.logins.metrics[b.metrics.logins.metrics.length - 1]
        .uniqueUserLogins90Days,
    0
  );

  return (
    <LearnMetricsChart
      {...props}
      strings={{
        title: t("learn.metrics.chart.uniqueLogins90Day.title"),
        titleHelp: t("learn.metrics.chart.uniqueLogins90Day.help.title"),
        peakUtilizationLabel: t(
          "learn.metrics.chart.uniqueLogins90Day.help.peakUtilizationLabel"
        ),
        peakUtilizationHelp: t(
          "learn.metrics.chart.uniqueLogins90Day.help.peakUtilizationHelp"
        ),
      }}
      valueFormatString={t(
        "learn.metrics.chart.uniqueLogins90Day.valueFormatString"
      )}
      valueSuffixString={t("learn.metrics.chart.uniqueLogins90Day.valueSuffix")}
      data={metrics}
      today={today}
      mix={true}
    />
  );
};
