import { makeStyles, Typography } from "@bb-ui/react-library";
import { Grid } from "@material-ui/core";
import { FunctionComponent, ReactNode } from "react";
import { Logo } from "../../../../components/Logo/Logo";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 500,
  },
  logo: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  image: {
    maxWidth: 175,
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  message: {
    marginBottom: theme.spacing(4),
  },
}));

export interface ErrorPageProps {
  image: ReactNode;
  title?: string;
  message?: string;
}

export const ErrorPage: FunctionComponent<ErrorPageProps> = ({
  image,
  message,
  title,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.root}
      justifyContent={"space-around"}
    >
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Grid item className={classes.logo}>
            <Logo size={"xlarge"} />
          </Grid>
          <Typography
            className={classes.title}
            variant={"h1"}
            data-test="error-title"
          >
            {title}
          </Typography>
          <div className={classes.image}>{image}</div>
          <Typography
            className={classes.message}
            variant={"subtitle2"}
            data-test="error-message"
          >
            {message}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
