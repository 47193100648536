import { i18n } from "@bb-ui/i18n/dist";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { CustomGrid, Widget } from "../../../../../components/Widget/Widget";
import { GetLearnInstancesWithMetricsByCaptainIdsQuery } from "../../../../../graphql/types";
import { toDate, toLocalString } from "../../../../../time/time-parser";
import { LearnMetricsPieChart } from "../../../components/LearnMetricsPieChart";

export interface ActiveCoursesWidgetProps extends CustomGrid {
  readonly learns: GetLearnInstancesWithMetricsByCaptainIdsQuery["listLearnInstances"]["items"];
  readonly stackCharts: boolean;
}

export const ActiveCoursesWidget: FunctionComponent<
  ActiveCoursesWidgetProps
> = ({ learns, ...other }) => {
  const { t } = useTranslation();
  const lastUpdated = toLocalString(
    learns
      .map((learn) => toDate(learn.lastUpdated))
      .reduce((a, b) => (a > b ? a : b), new Date(0)),
    i18n.i18n.language
  );

  let activeUltraCourseCountStatusClassic = 0;
  let activeUltraCourseCountStatusPreview = 0;
  let activeUltraCourseCountStatusUltra = 0;
  let activeUltraCourseCountStatusUndecided = 0;

  learns.forEach((l) => {
    console.log(l.metrics.courses);

    const course = l.metrics.courses.metrics[0];
    activeUltraCourseCountStatusClassic +=
      course.activeUltraCourseCountStatusClassic;
    activeUltraCourseCountStatusPreview +=
      course.activeUltraCourseCountStatusPreview;
    activeUltraCourseCountStatusUltra +=
      course.activeUltraCourseCountStatusUltra;
    activeUltraCourseCountStatusUndecided +=
      course.activeUltraCourseCountStatusUndecided;
  });

  const data = learns
    .map(() => ({
      Original: activeUltraCourseCountStatusClassic,
      "Ultra Preview": activeUltraCourseCountStatusPreview,
      Ultra: activeUltraCourseCountStatusUltra,
      Undecided: activeUltraCourseCountStatusUndecided,
    }))
    .reduce((coll, metricData) => {
      Object.keys(metricData).forEach((key) => {
        coll[key] =
          (coll[key] ?? 0) + metricData[key as keyof typeof metricData];
      });

      return coll;
    }, {} as { [key: string]: number });

  const activeCourses = Object.keys(data)
    .filter((key) => data[key] != 0)
    .map((key) => ({
      name: key,
      value: data[key],
    }));

  const sortedMetrics: { name: string; value: number }[] = activeCourses.sort(
    (n1, n2) => {
      if (n1.value > n2.value) {
        return 1;
      }
      if (n1.value < n2.value) {
        return -1;
      }
      return 0;
    }
  );

  return (
    <Widget
      {...other}
      title={t("learn.metrics.chart.activeCourses.title")}
      titleHelp={t("learn.metrics.chart.activeCourses.help")}
    >
      <LearnMetricsPieChart
        metrics={sortedMetrics}
        lastUpdated={lastUpdated}
        title={t("learn.metrics.chart.activeCourses.title")}
        valueFormatString={t(
          "learn.metrics.chart.activeCourses.valueFormatString"
        )}
      />
    </Widget>
  );
};
