import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { CustomGrid } from "../../../../../components/Widget/Widget";
import { GetLearnInstancesWithMetricsByCaptainIdsQuery } from "../../../../../graphql/types";
import {
  LearnMetricsChartProps,
  LearnMetricsChart,
} from "../../../components/LearnMetricsChart";

export interface AvailableUsersWidgetProps
  extends CustomGrid,
    Pick<LearnMetricsChartProps, "showEntitlementLine"> {
  readonly learns: GetLearnInstancesWithMetricsByCaptainIdsQuery["listLearnInstances"]["items"];
  readonly showEntitlements?: boolean;
  readonly stackCharts: boolean;
}

export const AvailableUsersWidget: FunctionComponent<
  AvailableUsersWidgetProps
> = ({ learns, showEntitlements, ...props }) => {
  const { t } = useTranslation();
  const metrics = learns.map((l) => ({
    learn: l,
    metrics: l.metrics.users.metrics.map((m) => ({
      date: m.date,
      captainId: l.captainId,
      title: l.url,
      total: m.availableUsers,
      status: l.status,
    })),
  }));

  const today = learns.reduce(
    (a, b) =>
      a +
      b.metrics.users.metrics[b.metrics.users.metrics.length - 1]
        .availableUsers,
    0
  );

  return (
    <LearnMetricsChart
      {...props}
      strings={{
        title: t("learn.metrics.chart.availableUsers.title"),
        titleHelp: t("learn.metrics.chart.availableUsers.help.title"),
        activeEntitlement: t(
          "learn.metrics.chart.availableUsers.help.activeEntitlement"
        ),
        entitlementUtilization: t(
          "learn.metrics.chart.availableUsers.help.entitlementUtilization"
        ),
        peakUtilizationLabel: t(
          "learn.metrics.chart.availableUsers.help.peakUtilizationLabel"
        ),
        peakUtilizationHelp: t(
          "learn.metrics.chart.availableUsers.help.peakUtilizationHelp"
        ),
      }}
      valueFormatString={t(
        "learn.metrics.chart.availableUsers.valueFormatString"
      )}
      valueSuffixString={t("learn.metrics.chart.availableUsers.valueSuffix")}
      data={metrics}
      today={today}
    />
  );
};
