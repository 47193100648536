import Grid from "@material-ui/core/Grid";
import { FunctionComponent } from "react";
import { GetLearnInstancesWithMetricsByCaptainIdsQuery } from "../../../graphql/types";
import { AvailableUsersWidget } from "./widgets/AvailableUsersWidget";
import { BillableUsersWidget } from "./widgets/BillableUsersWidget";

export interface LearnUserSectionProps {
  readonly learns: GetLearnInstancesWithMetricsByCaptainIdsQuery["listLearnInstances"]["items"];
  readonly stackCharts: boolean;
}

export const LearnUserSection: FunctionComponent<LearnUserSectionProps> = ({
  learns,
  ...props
}) => {
  return (
    <Grid container direction={"row"}>
      <AvailableUsersWidget
        data-test={"available-users-graph"}
        stackCharts={props.stackCharts}
        learns={learns}
        xs={12}
        sm={12}
        md={6}
        lg={6}
      />
      <BillableUsersWidget
        data-test={"billable-users-graph"}
        stackCharts={props.stackCharts}
        learns={learns}
        xs={12}
        sm={12}
        md={6}
        lg={6}
      />
    </Grid>
  );
};
