import { DownloadFile } from "@bb-ui/icons/dist/small";
import { CircularProgress } from "@bb-ui/react-library";
import { DateTimeFormatter, LocalDate } from "@js-joda/core";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Papa from "papaparse";
import { FunctionComponent, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { HelpTooltip } from "../../../../components/HelpTooltip/HelpTooltip";
import { ToolButton } from "../ToolButton";
export interface CSVProperties {
  readonly read: () => Promise<CSVRow[]>;
  readonly prefixName: string;
  readonly help?: string;
}
export type CSVRow = { [key: string]: string | number };
export const CSVDownload: FunctionComponent<CSVProperties> = ({
  read,
  prefixName,
  help,
  ...others
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { t } = useTranslation();
  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={error}
        autoHideDuration={6000}
        onClose={() => setError(false)}
      >
        <Alert
          severity="error"
          onClose={() => {
            setError(false);
          }}
        >
          {t("learn.components.csv.errorMessage")}
        </Alert>
      </Snackbar>
      <HelpTooltip
        help={
          help ?? t("learn.metrics.chart.sections.controls.downloadCSV.help")
        }
        icon={false}
      >
        {loading ? (
          <CircularProgress ariaLabel={"csv loading"} />
        ) : (
          <ToolButton
            disabled={loading}
            {...others}
            onClick={async () => {
              setLoading(true);
              setError(false);
              try {
                const data = await read();
                const csv = Papa.unparse(data);
                const anchor = window.document.createElement("a");
                const blob = new Blob([csv], { type: "text/csv" });
                anchor.href = window.URL.createObjectURL(blob);
                anchor.download = `${prefixName}-${LocalDate.now().format(
                  DateTimeFormatter.ofPattern("yyyy-MM-dd")
                )}.csv`;
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                window.URL.revokeObjectURL(anchor.href);
              } catch (e) {
                console.error("fail to pull the data", e);
                setError(true);
              } finally {
                setLoading(false);
              }
            }}
            startIcon={<DownloadFile />}
          >
            {t("learn.metrics.chart.sections.controls.downloadCSV.label")}
          </ToolButton>
        )}
      </HelpTooltip>
    </Fragment>
  );
};
