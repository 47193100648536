import { Typography } from "@bb-ui/react-library/dist/components/Typography";
import * as React from "react";
import { useTranslation } from "react-i18next";

export interface ChartLastUpdatedProps {
  lastUpdated?: string;
}

export const ChartLastUpdated: React.FunctionComponent<
  ChartLastUpdatedProps
> = (props) => {
  const { t } = useTranslation();

  return (
    <Typography variant="body1" aria-label={t("chart.subtitle")}>
      <b>{t("chart.subtitle")} </b> {props.lastUpdated}
    </Typography>
  );
};
