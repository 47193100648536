import { i18n } from "@bb-ui/i18n/dist";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { CustomGrid, Widget } from "../../../../../components/Widget/Widget";
import { GetLearnInstancesWithMetricsByCaptainIdsQuery } from "../../../../../graphql/types";
import { toDate, toLocalString } from "../../../../../time/time-parser";
import { LearnMetricsPieChart } from "../../../components/LearnMetricsPieChart";

export interface FileSizesByTypeWidgetProps extends CustomGrid {
  readonly learns: GetLearnInstancesWithMetricsByCaptainIdsQuery["listLearnInstances"]["items"];
}

export const FileSizesByTypeWidget: FunctionComponent<
  FileSizesByTypeWidgetProps
> = ({ learns, ...other }) => {
  const { t } = useTranslation();

  const lastUpdated = toLocalString(
    learns
      .map((learn) => toDate(learn.lastUpdated))
      .reduce((a, b) => (a > b ? a : b), new Date(0)),
    i18n.i18n.language
  );

  let audio = 0;
  let documents = 0;
  let images = 0;
  let others = 0;
  let video = 0;
  let zipArchives = 0;

  // For each Learn instance, take the latest metric and roll them up
  learns.forEach((l) => {
    const metric = l.metrics.storage.metrics[0];

    audio += metric.fileSizes.audio;
    documents += metric.fileSizes.documents;
    images += metric.fileSizes.images;
    others += metric.fileSizes.other;
    video += metric.fileSizes.video;
    zipArchives += metric.fileSizes.archived;
  });

  const data = learns
    .map(() => {
      const row: { [keys: string]: number } = {};
      row[t("learn.metrics.chart.fileSizeByType.labels.images")] = images;
      row[t("learn.metrics.chart.fileSizeByType.labels.audio")] = audio;
      row[t("learn.metrics.chart.fileSizeByType.labels.video")] = video;
      row[t("learn.metrics.chart.fileSizeByType.labels.documents")] = documents;
      row[t("learn.metrics.chart.fileSizeByType.labels.archived")] =
        zipArchives;
      row[t("learn.metrics.chart.fileSizeByType.labels.other")] = others;
      return row;
    })
    .reduce((coll, metricData) => {
      Object.keys(metricData).forEach((key) => {
        coll[key] =
          (coll[key] ?? 0) + metricData[key as keyof typeof metricData];
      });

      return coll;
    }, {} as { [key: string]: number });

  const metrics = Object.keys(data)
    .filter((key) => data[key] != 0)
    .map((key) => ({
      name: key,
      value: data[key],
    }));

  return (
    <Widget
      {...other}
      title={t("learn.metrics.chart.fileSizeByType.title")}
      titleHelp={t("learn.metrics.chart.fileSizeByType.help")}
    >
      <LearnMetricsPieChart
        metrics={metrics}
        lastUpdated={lastUpdated}
        title={t("learn.metrics.chart.fileSizeByType.title")}
        valueFormatString={t(
          "learn.metrics.chart.fileSizeByType.valueFormatString"
        )}
      />
    </Widget>
  );
};
