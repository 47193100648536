import { DocumentNode, gql } from "@apollo/client";

export const exchangeTenantIdForCaptainId: DocumentNode = gql`
  query ExchangeTenantIdForCaptainId($tenantId: ID!) {
    exchangeTenantIdForCaptainId(tenantId: $tenantId)
  }
`;

export const getLearnByCaptainId: DocumentNode = gql`
  query GetLearnByCaptainId($captainId: ID, $tenantId: ID) {
    getLearnInstance(filter: { captainId: $captainId, tenantId: $tenantId }) {
      url
      name
      captainId
      environment
      lastUpdated
      support {
        type
        name
        phone
        email
      }
    }
  }
`;

export const getLearnInstancesWithMetricsByCaptainIds: DocumentNode = gql`
  query GetLearnInstancesWithMetricsByCaptainIds(
    $captainIds: [ID!]!
    $limit: Int
  ) {
    listLearnInstances(filter: { captainIds: $captainIds, limit: $limit }) {
      items {
        url
        name
        captainId
        environment
        lastUpdated
        status
        metrics(filter: { limit: $limit }) {
          courses {
            metrics {
              date
              activeUltraCourseCountStatusClassic
              activeUltraCourseCountStatusPreview
              activeUltraCourseCountStatusUltra
              activeUltraCourseCountStatusUndecided
              courseOrgCountAutoArchive
              dailyCoursePageViewCount
              dailyPageViewCount
              totalCourseCount
              ultraCourseCountStatusClassic
              ultraCourseCountStatusPreview
              ultraCourseCountStatusUltra
              ultraCourseCountStatusUndecided
            }
          }
          logins {
            metrics {
              date
              dailyFailedLogins
              dailySessionCount
              dailySuccessfulLogins
              uniqueUserLogins30Days
              uniqueUserLogins90Days
              uniqueUserLogins180Days
            }
          }
          site {
            metrics {
              date
              appTimezone
              city
              country
              environment
              etlEnabled
              identifyingTag
              ldpEnabled
              learnRelease
              name
              peoplesoftId
              pluginCount
              primaryMarket
              reportingDataSource
              salesforceAssetName
              salesforceId
              state
              status
              ultraChoiceEnabled
              ultraEnabled
              url
              xythosRelease
            }
          }
          storage {
            metrics {
              date
              fileCounts {
                archived
                audio
                documents
                images
                video
                other
              }
              fileSizes {
                archived
                audio
                documents
                images
                video
                other
              }
              deleted
              internalArchive
              internalLogs
              sharedContent
              xythosContent
              total
            }
          }
          users {
            metrics {
              date
              availableUsers
              billableUsers
            }
          }
        }
      }
    }
  }
`;

export const listLearnInstanceSiblings: DocumentNode = gql`
  query ListLearnSiblings($nextToken: String, $limit: Int) {
    listLearnInstances(filter: { nextToken: $nextToken, limit: $limit }) {
      items {
        captainId
        clientId
        environment
        identifyingTag
        learnVersion
        name
        peoplesoftId
        salesforceId
        status
        url
      }
    }
  }
`;

export const getLearnInstanceHealth: DocumentNode = gql`
  query GetLearnInstanceHealth($captainId: ID!) {
    getLearnInstanceHealth(filter: { captainId: $captainId }) {
      apdexChartChartUrl
      apdexValueOnlyChartUrl
      loginPageViewChartUrl
      throughputChartUrl
      uniqueUserIdChartUrl
      uptimeChartUrl
      uptimeValueOnlyChartUrl
    }
  }
`;

export const getLearnHealthEntity: DocumentNode = gql`
  query GetLearnHealthEntity($captainId: ID!) {
    getLearnHealthEntity(filter: { captainId: $captainId }) {
      accountId
      domain
      entityType
      guid
      name
      tags {
        key
        values
      }
    }
  }
`;
