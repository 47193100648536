import {
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLearn } from "../useLearn";

export const LearnSitesHomePage: FunctionComponent = () => {
  const { siblings } = useLearn();
  const { t } = useTranslation();

  type LearnSites = Array<{
    captainId: string;
    url: string;
    region: string;
    version: string;
  }>;

  const allResults: LearnSites = [
    {
      captainId: "",
      url: "",
      region: "",
      version: "",
    },
  ];

  siblings.forEach((learn) => {
    let country = "";
    let gov = "";
    let direction = "";
    let number = "";
    let awsRegion = "";

    if (learn.environment.startsWith("us-gov")) {
      country = learn.environment.split("-")[0];
      gov = learn.environment.split("-")[1];
      direction = learn.environment.split("-")[2];
      number = learn.environment.split("-")[3];
      awsRegion = country + "-" + gov + "-" + direction + "-" + number;
    } else {
      country = learn.environment.split("-")[0];
      direction = learn.environment.split("-")[1];
      number = learn.environment.split("-")[2];
      awsRegion = country + "-" + direction + "-" + number;
    }

    let row = {
      captainId: learn.captainId,
      url: learn.url,
      region: awsRegion,
      version: learn.learnVersion,
    };
    allResults.push(row);
  });
  allResults.sort((a, b) => a.url.localeCompare(b.url)).shift();

  const [name, setName] = useState("");
  const [foundResults, setFoundResults] = useState(allResults);

  const filter = (e: any) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const searchResults = allResults.filter((learn) => {
        return learn.url.toLowerCase().includes(keyword.toLowerCase());
      });
      setFoundResults(searchResults);
    } else {
      setFoundResults(allResults);
    }

    setName(keyword);
  };

  return (
    <>
      <TextField
        data-test={"sites-test"}
        fullWidth={true}
        type="search"
        value={name}
        onChange={filter}
        placeholder={t("learn.sites.search.help")}
        InputProps={{
          endAdornment: (
            <InputAdornment position={"end"}>
              <IconButton>
                <Search />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Site</TableCell>
              <TableCell>Version</TableCell>
              <TableCell>Region</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {foundResults && foundResults.length > 0
              ? foundResults.map((learn) => (
                  <TableRow key={learn.captainId}>
                    <TableCell component="th" scope="row">
                      {learn.url}
                    </TableCell>
                    <TableCell>{learn.version}</TableCell>
                    <TableCell>{learn.region}</TableCell>
                  </TableRow>
                ))
              : allResults.map((learn) => (
                  <TableRow key={learn.captainId}>
                    <TableCell component="th" scope="row">
                      {learn.url}
                    </TableCell>
                    <TableCell>{learn.version}</TableCell>
                    <TableCell>{learn.region}</TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
