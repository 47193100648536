import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { AccessibleImage } from "../../components/AccessibleImage";
import { ErrorPage } from "./components/ErrorPage";
import error from "./error.svg";

export const NoHealthMetricsFoundPage: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <ErrorPage
      image={<AccessibleImage src={error} alt={t("errorpages.health.title")} />}
      title={t("errorpages.health.title")}
      message={t("errorpages.health.message")}
    />
  );
};
