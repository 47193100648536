import React, { ErrorInfo, ReactNode } from "react";
interface Props {
  children?: ReactNode;
  fallback: NonNullable<ReactNode>;
}

interface State {
  hasError: boolean;
}
export class ErrorBoundary extends React.Component<Props, State> {
  public static getDerivedStateFromError(_: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback;
    }

    return this.props.children;
  }
}
