const {
  REACT_APP_UAS_AUDIENCE,
  REACT_APP_UAS_CLIENT_ID,
  REACT_APP_UAS_ISSUER,
  REACT_APP_UAS_SIGNIN,
  REACT_APP_GRAPHQL_ENDPOINT,
  REACT_APP_GRAPHQL_REGION,
} = (window as any)._cp_config;

export const config = {
  uas: {
    audienceNamespace: REACT_APP_UAS_AUDIENCE as string,
    client_id: REACT_APP_UAS_CLIENT_ID as string,
    domain: REACT_APP_UAS_ISSUER as string,
    signinUrl: REACT_APP_UAS_SIGNIN as string,
  },
  graphqlEndpoint: REACT_APP_GRAPHQL_ENDPOINT as string,
  graphqlRegion: REACT_APP_GRAPHQL_REGION as string,
};

export default config;
