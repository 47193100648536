import {
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
} from "@bb-ui/react-library";
import { Grid } from "@material-ui/core";
import { Email, Phone, Web } from "@material-ui/icons";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Logo } from "../components/Logo/Logo";

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(1, 1, 1, 1),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.b3,
    "& a": {
      color: "inherit",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& svg": {
        fontSize: "0.9rem",
        marginRight: 2,
      },
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  logo: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(2),
    },
  },
  left: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignContent: "center",
      alignItems: "center",
    },
  },
  right: {
    justifyContent: "flex-end",
    [theme.breakpoints.only("xs")]: {
      flexDirection: "column",
      alignContent: "center",
      alignItems: "center",
    },
  },
}));

export const Footer: FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const supportContactEmail = t("help.sections.standard.email.value");
  const supportContactPhone = t("help.sections.standard.phone.value");

  return (
    <>
      <Grid
        component={"footer"}
        container
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        className={classes.footer}
      >
        <Grid item>
          <Grid container>
            <Grid item>
              <Link href={`mailto:${supportContactEmail}`}>
                <ListItem>
                  <ListItemIcon>
                    <Email />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography component="span" variant="subtitle2">
                      {t("help.sections.standard.email.label", {
                        email: supportContactEmail,
                      })}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>
            </Grid>
            <Grid item>
              <Link href={`tel:${supportContactPhone}`}>
                <ListItem>
                  <ListItemIcon>
                    <Phone />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography component="span" variant="subtitle2">
                      {t("help.sections.standard.phone.label", {
                        number: supportContactPhone,
                      })}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>
            </Grid>
            <Grid item>
              <Link
                href={t("help.sections.standard.web.value")}
                target="_blank"
              >
                <ListItem>
                  <ListItemIcon>
                    <Web />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography component="span" variant="subtitle2">
                      {t("help.sections.standard.web.label")}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>
            </Grid>
            <Grid item>
              <Link
                href={t("help.sections.standard.behindBlackboard.value")}
                target="_blank"
              >
                <ListItem>
                  <ListItemIcon>
                    <Web />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography component="span" variant="subtitle2">
                      {t("help.sections.standard.behindBlackboard.label")}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>
            </Grid>
            <Grid item>
              <Link
                href={t("help.sections.standard.community.value")}
                target="_blank"
              >
                <ListItem>
                  <ListItemIcon>
                    <Web />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography component="span" variant="subtitle2">
                      {t("help.sections.standard.community.label")}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>
            </Grid>
            <Grid item>
              <Link
                href={t("help.sections.standard.blackboardTechiesSlack.value")}
                target="_blank"
              >
                <ListItem>
                  <ListItemIcon>
                    <Web />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography component="span" variant="subtitle2">
                      {t("help.sections.standard.blackboardTechiesSlack.label")}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Logo copyright={true} />
        </Grid>
      </Grid>
    </>
  );
};

export default Footer;
