import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { ErrorPage } from "./components/ErrorPage";
import error from "./error.svg";

export const LtiError: FunctionComponent = () => {
  const { t } = useTranslation();
  const [params] = useSearchParams();

  return (
    <ErrorPage
      image={error}
      title={t("errorpages.ltiError.title")}
      message={t(
        params.get("message") ?? "errorpages.ltiError.messages.unknown"
      )}
    />
  );
};
