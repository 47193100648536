import { makeStyles } from "@bb-ui/react-library";
import Grid from "@material-ui/core/Grid";
import { FunctionComponent } from "react";
import { Widget } from "../../../../components/Widget/Widget";
import { FailedToFetchPage } from "../../../errorpages/FailedToFetchPage";

export interface NewRelicBillboardChartProps {
  title: string;
  titleHelp: string;
  embeddedChartLink: string | null | undefined;
}

const useStyles = makeStyles(() => ({
  iframe: {
    height: 100,
    width: 150,
    border: "0",
    overflow: "visible",
  },
}));

export const NewRelicBillboardChart: FunctionComponent<
  NewRelicBillboardChartProps
> = ({ title, titleHelp, embeddedChartLink, ...props }) => {
  const classes = useStyles();
  let url = undefined;
  if (embeddedChartLink != null && embeddedChartLink.startsWith("https")) {
    url = new URL(embeddedChartLink);
    url.searchParams.set("timepicker", "false");
  }

  return (
    <Widget
      xs={3}
      title={title}
      titleHelp={titleHelp}
      variant={"bordered"}
      {...props}
    >
      {!embeddedChartLink || embeddedChartLink.startsWith("failed") ? (
        <FailedToFetchPage />
      ) : (
        <Grid container direction={"column"}>
          <iframe className={classes.iframe} src={url ? url.toString() : ""} />
        </Grid>
      )}
    </Widget>
  );
};
