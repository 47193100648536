import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum AuthProviderType {
  UAS,
  DEVPORTAL,
}

export interface AppState {
  readonly returnUrl?: string;
  readonly provider?: AuthProviderType;
}

const initialState: AppState = {};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setReturnUrl(state, action: PayloadAction<string | undefined>) {
      state.returnUrl = action.payload;
    },
    setProvider(state, action: PayloadAction<AuthProviderType | undefined>) {
      state.provider = action.payload;
    },
    resetState(state) {
      state.returnUrl = undefined;
      state.provider = undefined;
    },
  },
});

export const { resetState, setReturnUrl } = slice.actions;
export default slice.reducer;
