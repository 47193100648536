import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { AuthenticationError } from "../errorpages/AuthenticationError";
import { SessionTimeoutErrorPage } from "../errorpages/SessionTimeoutErrorPage";
import { useAppAuth } from "./UserProvider";

export const AuthGate: FunctionComponent = ({ children }) => {
  const { expired, tenantId, token } = useAppAuth();
  const { t } = useTranslation();

  if (expired) {
    return <SessionTimeoutErrorPage />;
  }

  if (!tenantId || tenantId === "") {
    return <AuthenticationError message={t("errorpages.auth.tenantMessage")} />;
  }

  if (!token || token === "") {
    return <AuthenticationError message={t("errorpages.auth.tokenMessage")} />;
  }

  return <>{children}</>;
};
