import { Help } from "@bb-ui/icons/dist/small";
import { Tooltip } from "@bb-ui/react-library";
import {
  createStyles,
  GridProps,
  makeStyles,
  TooltipProps,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import * as React from "react";

export interface HelpTooltipProps extends GridProps {
  help: React.ReactNode;
  icon?: boolean | React.ReactNode;
  placement?: TooltipProps["placement"];
}

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      cursor: "default",
    },
    wrapper: {
      display: "flex",
      justifyContent: "flex-start",
    },
    helpIcon: {
      fontSize: 12,
      marginTop: 2,
      marginLeft: 4,
      cursor: "help",
    },
  })
);

export const HelpTooltip: React.FunctionComponent<HelpTooltipProps> = ({
  children,
  className,
  help,
  icon,
  placement,
  ...other
}) => {
  const classes = useStyles();

  return (
    <Grid item className={clsx(classes.root, className)} {...other}>
      <Tooltip title={help ?? "Unknown"} placement={placement ?? "right-start"}>
        <span className={classes.wrapper}>
          {children}
          {(() =>
            icon === true || icon === undefined ? (
              <Help className={classes.helpIcon} />
            ) : (
              icon
            ))()}
        </span>
      </Tooltip>
    </Grid>
  );
};
