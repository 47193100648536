import { List, ListItem } from "@bb-ui/react-library";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { FunctionComponent } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from "recharts";
import { getColorForIndex } from "../../../../app/colors";
import { ChartLastUpdated } from "../../../../components/ChartLastUpdated";
import { formatNumber } from "../../../../util/numbers";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
}));

export interface LearnMetricsPieChartProps {
  valueFormatString: string;
  metrics: {
    name: string;
    value: number;
  }[];
  totalSizeMetrics?: {
    name: string;
    value: number;
  }[];
  lastUpdated: string;
  title: string;
  _activeIndex?: number;
  mix?: boolean;
}

/**
 * Renders the active shape for the pie chart
 *
 * @param opts
 */
export const _renderActiveShape =
  (opts: {
    showAll: boolean;
    active: boolean;
    onMouseEnter: (_: unknown, index: number) => void;
  }) =>
  (props: any) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      index,
      payload,
      percent,
      value,
    } = props;

    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    if (!opts.active) {
      return <g key={value} />;
    }

    return (
      <g onMouseEnter={() => opts.onMouseEnter(undefined, index)} key={value}>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        {(() =>
          opts.showAll && (
            <Sector
              cx={cx}
              cy={cy}
              startAngle={startAngle}
              endAngle={endAngle}
              innerRadius={outerRadius + 6}
              outerRadius={outerRadius + 10}
              fill={fill}
            />
          ))()}
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`${payload.name}: ${formatNumber("0%", percent)}`}</text>
      </g>
    );
  };

export const LearnMetricsPieChart: FunctionComponent<
  LearnMetricsPieChartProps
> = ({
  metrics,
  totalSizeMetrics,
  lastUpdated,
  title,
  valueFormatString,
  ...props
}) => {
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = React.useState<number | undefined>(
    props._activeIndex
  );

  React.useEffect(() => {
    return () => {
      setActiveIndex(undefined);
    };
  }, []);

  const handleMouseLeave = React.useCallback(() => {
    setActiveIndex(undefined);
  }, []);

  const handleMouseEnter = React.useCallback(
    (_: unknown, index: number) => {
      if (index !== undefined) {
        setActiveIndex(index);
      }
    },
    [activeIndex]
  );

  return (
    <Grid container item xs={12} className={classes.root}>
      <Grid item xs={12}>
        <ResponsiveContainer minHeight={400} debounce={0.5}>
          <PieChart data-test={"pipe-chart"}>
            <Pie
              activeIndex={activeIndex}
              activeShape={_renderActiveShape({
                showAll: true,
                active: true,
                onMouseEnter: handleMouseEnter,
              })}
              label={_renderActiveShape({
                showAll: false,
                active: activeIndex === undefined,
                onMouseEnter: handleMouseEnter,
              })}
              labelLine={false}
              data={metrics}
              nameKey={"name"}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius={"50%"}
              outerRadius={"75%"}
              fill="#8884d8"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              paddingAngle={5}
            >
              {metrics.map((_, index) => (
                <Cell
                  key={`pie-cell-${index}`}
                  className={`pie-cell pie-cell-${index}`}
                  fill={
                    props.mix === undefined
                      ? getColorForIndex(index)
                      : getColorForIndex(index, true)
                  }
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Grid>
      <Grid item container direction={"column"} alignContent={"flex-end"}>
        <Grid item>
          <List dense>
            <ListItem>
              <ChartLastUpdated lastUpdated={lastUpdated} />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
};
