import { Picker } from "@bb-ui/react-library";
import { useTranslation } from "react-i18next";
import { HelpTooltip } from "../../../../components/HelpTooltip/HelpTooltip";

export interface ResolutionPickerProps<T> {
  readonly onChange: (resolution: string) => void;
  readonly value: T;
  readonly supportedResolutions: {
    label: string;
    value: T;
  }[];
}

export function ResolutionPicker<T>(props: ResolutionPickerProps<T>) {
  const { t } = useTranslation();
  const { supportedResolutions, onChange, value } = props;
  return (
    <Picker
      label={
        <HelpTooltip help={t("learn.components.resolutionPicker.help")}>
          {t("learn.components.resolutionPicker.label")}
        </HelpTooltip>
      }
      options={supportedResolutions}
      value={supportedResolutions.find((ts) => ts.value === value)}
      onChange={(val: any) => onChange(val.value)}
    />
  );
}
