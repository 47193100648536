import "@js-joda/timezone";
import { ZonedDateTime, ZoneOffset } from "@js-joda/core";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { CustomGrid } from "../../../../../components/Widget/Widget";
import { GetLearnInstancesWithMetricsByCaptainIdsQuery } from "../../../../../graphql/types";
import {
  LearnMetricsChartProps,
  LearnMetricsChart,
} from "../../../components/LearnMetricsChart";

export interface StorageUsageWidgetProps
  extends CustomGrid,
    Pick<LearnMetricsChartProps, "showEntitlementLine" | "stackCharts"> {
  readonly learns: GetLearnInstancesWithMetricsByCaptainIdsQuery["listLearnInstances"]["items"];
  readonly entitlements?: number;
  readonly showEntitlements?: boolean;
  readonly stackCharts?: boolean;
}

const cutoff = ZonedDateTime.parse("2022-04-21T00:00:00.000Z").toEpochSecond();

export const StorageUsageWidget: FunctionComponent<StorageUsageWidgetProps> = ({
  learns,
  showEntitlements,
  entitlements,
  ...other
}) => {
  const { t } = useTranslation();
  const convertForBase10Change = React.useCallback(
    (total: number, date: string) => {
      if (
        ZonedDateTime.parse(date)
          .toInstant()
          .atZone(ZoneOffset.UTC)
          .toEpochSecond() >= cutoff
      ) {
        return total;
      }

      return (total / 1073741824) * 1000000000;
    },
    []
  );
  const metrics = learns.map((l) => ({
    learn: l,
    metrics: l.metrics.storage.metrics.map((m) => ({
      date: m.date,
      captainId: l.captainId,
      title: l.url,
      // We are premature in removing the deleted and internalArchive
      // total: convertForBase10Change(
      //   m.xythosContent + m.sharedContent - m.deleted - m.internalArchive,
      //   m.date
      // ),
      total: convertForBase10Change(m.total, m.date),
    })),
  }));

  const today = learns.reduce(
    (a, b) =>
      a + b.metrics.storage.metrics[b.metrics.storage.metrics.length - 1].total,
    0
  );

  const entitledInBytes = entitlements ? entitlements * 1000 * 1000 * 1000 : -1;

  return (
    <LearnMetricsChart
      {...other}
      strings={{
        title: t("learn.metrics.chart.storage.title"),
        titleHelp: t("learn.metrics.chart.storage.help.title"),
        peakUtilizationLabel: t(
          "learn.metrics.chart.storage.help.peakUtilizationLabel"
        ),
        peakUtilizationHelp: t(
          "learn.metrics.chart.storage.help.peakUtilizationHelp"
        ),
      }}
      valueFormatString={t("learn.metrics.chart.storage.valueFormatString")}
      data={metrics}
      entitled={(showEntitlements && entitledInBytes) || undefined}
      today={today}
    />
  );
};
