import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import cloneDeep from "lodash.clonedeep";
import { RootState } from "../../app/store";
export enum ResolutionSpan {
  Daily,
  Monthly,
}
export enum MonthlySpans {
  YEAR = 12,
  SEMESTER = 6,
  QUARTER = 3,
}
export enum DailySpans {
  MONTH = 30,
  QUARTER = 90,
  SEMESTER = 180,
}
export const supportedResolutionSpans = [
  {
    label: "learn.components.resolutionPicker.values.daily",
    value: ResolutionSpan.Daily,
  },
  {
    label: "learn.components.resolutionPicker.values.monthly",
    value: ResolutionSpan.Monthly,
  },
];

export const supportedTimeSpansMonthly = [
  {
    label: "learn.components.timeSpanPicker.values.monthly.year",
    value: MonthlySpans.YEAR,
  },
  {
    label: "learn.components.timeSpanPicker.values.monthly.semester",
    value: MonthlySpans.SEMESTER,
  },
  {
    label: "learn.components.timeSpanPicker.values.monthly.quarter",
    value: MonthlySpans.QUARTER,
  },
];

export const supportedTimeSpansDaily = [
  {
    label: "learn.components.timeSpanPicker.values.daily.semester",
    value: DailySpans.SEMESTER,
  },
  {
    label: "learn.components.timeSpanPicker.values.daily.quarter",
    value: DailySpans.QUARTER,
  },
  {
    label: "learn.components.timeSpanPicker.values.daily.month",
    value: DailySpans.MONTH,
  },
];

export interface LearnState {
  captainId?: string;
  captainIds: string[];
  health?: {
    controls?: {
      resolution: string;
      timespan: string;
    };
  };
  metrics?: {
    controls?: {
      stacking: boolean;
      resolution: ResolutionSpan;
      timespan: MonthlySpans | DailySpans;
    };
  };
  entitlements?: {
    controls?: {
      showEntitlementLine: boolean;
      showAllLearns: boolean;
    };
  };
}

const initialState: LearnState = {
  captainIds: [],
  health: {
    controls: {
      resolution: "",
      timespan: "",
    },
  },
  metrics: {
    controls: {
      stacking: true,
      resolution: supportedResolutionSpans[0].value,
      timespan: supportedTimeSpansDaily[0].value,
    },
  },
  entitlements: {
    controls: {
      showEntitlementLine: false,
      showAllLearns: false,
    },
  },
};
export function isDailySpan(obj: any): obj is DailySpans {
  return obj in DailySpans;
}
const slice = createSlice({
  name: "learn",
  initialState,
  reducers: {
    setCaptainId(state, action: PayloadAction<string | undefined>) {
      state.captainId = action.payload;
      if (!state.captainIds.length && action.payload) {
        state.captainIds = [action.payload];
      }
    },
    setActiveCaptainIds(state, action: PayloadAction<string[]>) {
      state.captainIds = action.payload;
    },
    setMetricsChartStacking(state, action: PayloadAction<boolean>) {
      if (!state.metrics) {
        state.metrics = {};
      }

      if (!state.metrics.controls) {
        state.metrics.controls = {
          stacking: false,
          resolution: ResolutionSpan.Daily,
          timespan: DailySpans.SEMESTER,
        };
      }

      state.metrics.controls.stacking = action.payload;
    },
    setEntitlementsChartShowEntitlementLine(
      state,
      action: PayloadAction<boolean>
    ) {
      if (!state.entitlements) {
        state.entitlements = {};
      }

      if (!state.entitlements.controls) {
        state.entitlements.controls = {
          showEntitlementLine: false,
          showAllLearns: false,
        };
      }

      state.entitlements.controls.showEntitlementLine = action.payload;
    },
    setEntitlementsChartShowAllLearns(state, action: PayloadAction<boolean>) {
      if (!state.entitlements) {
        state.entitlements = {};
      }

      if (!state.entitlements.controls) {
        state.entitlements.controls = {
          showEntitlementLine: false,
          showAllLearns: true,
        };
      }

      state.entitlements.controls.showAllLearns = action.payload;
    },
    setMetricsChartResolution(current, action: PayloadAction<ResolutionSpan>) {
      const state = cloneDeep(current);
      if (!state.metrics) {
        state.metrics = {};
      }
      const defaultTimeSpan =
        action.payload == ResolutionSpan.Daily
          ? DailySpans.SEMESTER
          : MonthlySpans.YEAR;
      if (!state.metrics.controls) {
        state.metrics.controls = {
          stacking: false,
          resolution: action.payload,
          timespan: defaultTimeSpan,
        };
      } else {
        state.metrics.controls.resolution = action.payload;
        state.metrics.controls.timespan = defaultTimeSpan;
      }
      return state;
    },
    setMetricsChartTimeSpan(
      current,
      action: PayloadAction<DailySpans | MonthlySpans>
    ) {
      const state = cloneDeep(current);
      if (!state.metrics) {
        state.metrics = {};
      }
      if (!state.metrics.controls) {
        state.metrics.controls = {
          stacking: false,
          resolution: isDailySpan(action.payload)
            ? ResolutionSpan.Daily
            : ResolutionSpan.Monthly,
          timespan: action.payload,
        };
      } else {
        state.metrics.controls.timespan = action.payload;
      }
      return state;
    },
    setHealthChartResolution(state, action: PayloadAction<string>) {
      if (!state.health) {
        state.health = {};
      }

      if (!state.health.controls) {
        state.health.controls = { resolution: "", timespan: "" };
      }

      state.health.controls.resolution = action.payload;
    },
    setHealthChartTimeSpan(state, action: PayloadAction<string>) {
      if (!state.health) {
        state.health = {};
      }

      if (!state.health.controls) {
        state.health.controls = { resolution: "", timespan: "" };
      }

      state.health.controls.timespan = action.payload;
    },
  },
});

export const {
  setActiveCaptainIds,
  setCaptainId,
  setEntitlementsChartShowAllLearns,
  setEntitlementsChartShowEntitlementLine,
  setMetricsChartStacking,
  setMetricsChartResolution,
  setMetricsChartTimeSpan,
  setHealthChartResolution,
  setHealthChartTimeSpan,
} = slice.actions;
export default slice.reducer;
export const selectTimeResolution = (state: RootState) =>
  state.learn.metrics?.controls?.resolution ||
  supportedResolutionSpans[0].value;
export const selectTimeStamp = (state: RootState) =>
  state.learn.metrics?.controls?.timespan ||
  (state.learn.metrics?.controls?.resolution == ResolutionSpan.Daily
    ? supportedTimeSpansDaily[0].value
    : supportedTimeSpansMonthly[0].value);
export const selectAvailableResolutions = (state: RootState) =>
  state.learn.metrics?.controls?.resolution == ResolutionSpan.Daily
    ? supportedTimeSpansDaily
    : supportedTimeSpansMonthly;
