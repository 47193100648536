import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { AccessibleImage } from "../../components/AccessibleImage";
import { ErrorPage } from "./components/ErrorPage";
import error from "./error.svg";

export const UnknownErrorPage: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <ErrorPage
      image={
        <AccessibleImage src={error} alt={t("errorpages.accessDenied.title")} />
      }
      title={t("errorpages.unknown.title")}
      message={t("errorpages.unknown.message")}
    />
  );
};
