import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AppState {
  readonly theme?: "dark" | "light";
  readonly lang?: "en-US";
}

const initialState: AppState = {
  theme: "light",
};

const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setTheme(state, action: PayloadAction<AppState["theme"]>) {
      state.theme = action.payload;
    },
    setLang(state, action: PayloadAction<AppState["lang"]>) {
      state.lang = action.payload;
    },
  },
});

export const { setLang, setTheme } = slice.actions;
export default slice.reducer;
