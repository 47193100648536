import { BbThemeProvider } from "@bb-ui/react-library/dist/components/BbThemeProvider";
import { Box } from "@bb-ui/react-library/dist/components/Box";
import { getColor } from "@bb-ui/react-library/dist/components/styles";
import { Typography } from "@bb-ui/react-library/dist/components/Typography";
import { BoxProps, createStyles, makeStyles } from "@material-ui/core";
import clsx from "clsx";

export interface ErrorMessageProps extends BoxProps {
  /** Brief explanation of the error. */
  title?: string;
  /** Error message itself. Typically `error.message`. */
  message?: string;
  error?: Error;
  /** Override the default theme. Inherits parent theme if undefined. */
  theme?: "light" | "dark";
  /**
   * Set layout type.
   *
   * @default 'fill-content'
   *
   * 'fill-content': Fills width/height of container (if larger than component area), center aligned.
   * 'fill-window': Fills width/height of the window, covers content, center aligned.
   * 'block': Block level within content.
   * 'inline': Inline within content.
   */
  variant?: "fill-content" | "fill-window" | "block" | "inline";
}

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: (props: ErrorMessageProps) => ({
      color: props.theme ? getColor("text.primary", props.theme) : "inherit",
    }),
    fillWindow: (props: ErrorMessageProps) => ({
      display: "grid",
      justifyContent: "center",
      alignContent: "center",
      position: "fixed" as "fixed",
      zIndex: 99999,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: props.theme
        ? getColor("background.default", props.theme)
        : theme.palette.background.default,
      textAlign: "center",
    }),
    fillContent: {
      width: "100%",
      height: "100%",
      display: "grid",
      justifyContent: "center",
      alignContent: "center",
      textAlign: "center",
    },
    inline: {
      display: "inline-flex",
      "& $title, & $childrenArea": {
        margin: 0,
      },
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    childrenArea: {
      marginTop: theme.spacing(3),
    },
  })
);

export const ErrorMessage = (props: ErrorMessageProps) => {
  const classes = useStyles(props);
  const {
    title,
    message,
    children,
    error,
    theme,
    variant = "fill-content",
    className: classNameProp,
    ...other
  } = props;
  const classNames = clsx(
    classes.root,
    {
      [classes.fillWindow]: variant === "fill-window",
      [classes.fillContent]: variant === "fill-content",
      [classes.inline]: variant === "inline",
    },
    classNameProp
  );

  const Component = () => (
    <Box
      role="alert"
      className={classNames}
      data-testid="error-message"
      {...other}
    >
      <Typography className={classes.title} variant="h3">
        {title ?? "Unknown Error"}
      </Typography>
      <Typography color="error">{message ?? error?.message ?? ""}</Typography>
      {children && <div className={classes.childrenArea}>{children}</div>}
    </Box>
  );

  if (theme) {
    return (
      <BbThemeProvider theme={theme}>
        <Component />
      </BbThemeProvider>
    );
  }

  return <Component />;
};

export default ErrorMessage;
