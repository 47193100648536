import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  DocumentNode,
  gql,
  InMemoryCache,
  QueryOptions,
  useApolloClient,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { ServerError } from "@apollo/client/link/utils";
import { SessionStorageWrapper, CachePersistor } from "apollo3-cache-persist";
import { createAuthLink } from "aws-appsync-auth-link";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { suspend } from "suspend-react";
import { config } from "../app/config";
import { useAppAuth } from "../features/auth/UserProvider";
import { AuthenticationError } from "../features/errorpages/AuthenticationError";

function hasStatusCode(error: any): error is ServerError {
  return "statusCode" in error;
}

export const APIProvider: FunctionComponent = ({ children }) => {
  const { token, expired, ttlExpired, signOut } = useAppAuth();
  const [cacheReady, setCacheReady] = React.useState(false);
  const { t } = useTranslation();

  const errorLink = onError(({ networkError }) => {
    if (networkError && hasStatusCode(networkError)) {
      if (networkError.statusCode == 401) {
        console.error(networkError.result);
        signOut();
      }
    }
  });
  const metricsLink = React.useMemo(
    () =>
      ApolloLink.from([
        errorLink,
        createAuthLink({
          url: config.graphqlEndpoint,
          region: config.graphqlRegion,
          auth: {
            type: "OPENID_CONNECT",
            jwtToken: () => token,
          },
        }),
        createHttpLink({ uri: config.graphqlEndpoint }),
      ]),
    [config.graphqlEndpoint, config.graphqlRegion, token]
  );

  const link = React.useMemo(
    () =>
      ApolloLink.split(
        (operation) => operation.getContext().clientName === "newrelic",
        metricsLink,
        metricsLink
      ),
    [metricsLink, metricsLink]
  );

  const cache = React.useMemo(
    () =>
      new InMemoryCache({
        typePolicies: {
          Learn: {
            keyFields: ["captainId"],
          },
          Actor: {
            fields: {
              account: {
                keyArgs: ["accountId"],
              },
            },
          },
          Account: {
            keyFields: ["id"],
            fields: {
              nrql: {
                keyArgs: ["query"],
              },
            },
          },
          NrdbResultContainer: {
            keyFields: ["nrql"],
          },
        },
      }),
    [ttlExpired]
  );

  let persistor = new CachePersistor({
    cache,
    key: "bb-home-ui",
    storage: new SessionStorageWrapper(window.sessionStorage),
  });

  React.useEffect(() => {
    if (cache && !cacheReady) {
      persistor
        .persist()
        .then(() => setCacheReady(true))
        .catch((err) =>
          console.error(
            "Failed to persist cache properly, expect degraded app performance!",
            err
          )
        );
    }
  }, [cache, cacheReady, setCacheReady]);

  const client = React.useMemo(
    () =>
      cacheReady &&
      metricsLink &&
      new ApolloClient({
        link,
        cache,
      }),
    [metricsLink, cache, cacheReady]
  );

  // Expire the local cache if authentication expires or ttl expires
  React.useEffect(() => {
    if ((ttlExpired && client) || (expired && client)) {
      persistor.pause();
      void persistor.purge();
      void client.clearStore();
      void cache.reset();
      persistor.resume();
    }
  }, [ttlExpired, expired]);

  if (!client) {
    return <AuthenticationError message={t("errorpages.auth.apolloMessage")} />;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

type UseSuspendedQueryOptions<Query, Variables> = Omit<
  QueryOptions<Variables, Query>,
  "query"
>;

export function useSuspendedQuery<Query, Variables>(
  query: string | DocumentNode,
  props?: UseSuspendedQueryOptions<Query, Variables>
): Query {
  const client = useApolloClient();

  return suspend(async () => {
    const result = await client.query({
      query: typeof query === "string" ? gql(query) : query,
      ...props,
    });

    if (!result.data) {
      throw new Error("No data");
    }

    return result.data;
  }, [query, JSON.stringify(props?.variables)]);
}
