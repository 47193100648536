import { Picker } from "@bb-ui/react-library";
import { useTranslation } from "react-i18next";
import { HelpTooltip } from "../../../../components/HelpTooltip/HelpTooltip";

export interface TimeSpanPickerProps<T> {
  readonly onChange: (resolution: string) => void;
  readonly value: T;
  readonly supportedTimeSpans: {
    label: string;
    value: T;
  }[];
}

export function TimeSpanPicker<T>({
  onChange,
  supportedTimeSpans,
  value,
}: TimeSpanPickerProps<T>) {
  const { t } = useTranslation();

  return (
    <Picker
      label={
        <HelpTooltip help={t("learn.components.timeSpanPicker.help")}>
          {t("learn.components.timeSpanPicker.label")}
        </HelpTooltip>
      }
      options={supportedTimeSpans}
      value={supportedTimeSpans.find((ts) => ts.value === value)}
      onChange={(val: any) => onChange(val.value)}
    />
  );
}
