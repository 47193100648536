import Grid from "@material-ui/core/Grid";
import { FunctionComponent } from "react";
import { GetLearnInstancesWithMetricsByCaptainIdsQuery } from "../../../graphql/types";
import { DailyFailedLoginsWidget } from "./widgets/DailyFailedLoginsWidget";
import { DailySessionsWidget } from "./widgets/DailySessionsWidget";
import { DailySuccessfulLoginsWidget } from "./widgets/DailySuccessfulLoginsWidget";
import {
  UniqueUserLogins30DayWidget,
  UniqueUserLogins90DayWidget,
  UniqueUserLogins180DayWidget,
} from "./widgets/UniqueUserLoginsWidget";

export interface LearnLoginSectionProps {
  readonly learns: GetLearnInstancesWithMetricsByCaptainIdsQuery["listLearnInstances"]["items"];
  readonly stackCharts: boolean;
}

export const LearnLoginSection: FunctionComponent<LearnLoginSectionProps> = ({
  learns,
  ...props
}) => {
  return (
    <Grid container direction={"row"}>
      <UniqueUserLogins30DayWidget
        data-test={"unique-login"}
        stackCharts={props.stackCharts}
        learns={learns}
        xs={12}
        sm={12}
        md={6}
        lg={6}
      />
      <UniqueUserLogins90DayWidget
        data-test={"unique-login-90"}
        stackCharts={props.stackCharts}
        learns={learns}
        xs={12}
        sm={12}
        md={6}
        lg={6}
      />
      <UniqueUserLogins180DayWidget
        data-test={"unique-login-180"}
        stackCharts={props.stackCharts}
        learns={learns}
        xs={12}
        sm={12}
        md={6}
        lg={6}
      />
      <DailySuccessfulLoginsWidget
        data-test={"daily-succeed-login"}
        stackCharts={props.stackCharts}
        learns={learns}
        xs={12}
        sm={12}
        md={6}
        lg={6}
      />
      <DailyFailedLoginsWidget
        data-test={"daily-failed-login"}
        stackCharts={props.stackCharts}
        learns={learns}
        xs={12}
        sm={12}
        md={6}
        lg={6}
      />
      <DailySessionsWidget
        data-test={"daily-sessions-login"}
        stackCharts={props.stackCharts}
        learns={learns}
        xs={12}
        sm={12}
        md={6}
        lg={6}
      />
    </Grid>
  );
};
