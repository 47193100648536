import React, { FunctionComponent, useContext } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useSuspendedQuery } from "../../graphql/GraphqlProvider";
import {
  exchangeTenantIdForCaptainId,
  getLearnByCaptainId,
  listLearnInstanceSiblings,
} from "../../graphql/queries";
import {
  ExchangeTenantIdForCaptainIdQuery,
  ExchangeTenantIdForCaptainIdQueryVariables,
  GetLearnByCaptainIdQuery,
  GetLearnByCaptainIdQueryVariables,
  GetLearnInstancesWithMetricsByCaptainIdsQuery,
  ListLearnSiblingsQuery,
  ListLearnSiblingsQueryVariables,
} from "../../graphql/types";
import { useAppAuth } from "../auth/UserProvider";
import { setCaptainId } from "./learnSlice";

export interface Learn
  extends Omit<
    GetLearnByCaptainIdQuery["getLearnInstance"],
    "__typename" | "metrics"
  > {
  metrics?: Partial<
    GetLearnInstancesWithMetricsByCaptainIdsQuery["listLearnInstances"]["items"][0]["metrics"]
  >;
}

export type LearnContextContents = {
  learn: Learn;
  siblings: ListLearnSiblingsQuery["listLearnInstances"]["items"];
};

export const LearnContext = React.createContext<
  LearnContextContents | undefined
>({} as LearnContextContents);

export const LearnProvider: FunctionComponent = ({ children }) => {
  const { tenantId } = useAppAuth();
  const dispatch = useAppDispatch();
  let captainId = useAppSelector((s) => s.learn.captainId);

  if (!captainId) {
    const captainIdResult = useSuspendedQuery<
      ExchangeTenantIdForCaptainIdQuery,
      ExchangeTenantIdForCaptainIdQueryVariables
    >(exchangeTenantIdForCaptainId, {
      variables: {
        tenantId,
      },
    });

    dispatch(setCaptainId(captainIdResult.exchangeTenantIdForCaptainId));
  }

  if (!tenantId || !captainId) {
    return null;
  }

  const learnData = useSuspendedQuery<
    GetLearnByCaptainIdQuery,
    GetLearnByCaptainIdQueryVariables
  >(getLearnByCaptainId, {
    variables: {
      captainId,
      tenantId: !captainId ? tenantId : undefined,
    },
  });

  const siblingsData = useSuspendedQuery<
    ListLearnSiblingsQuery,
    ListLearnSiblingsQueryVariables
  >(listLearnInstanceSiblings, {
    variables: {
      limit: 200,
    },
  });

  return (
    <LearnContext.Provider
      value={{
        learn: learnData?.getLearnInstance,
        siblings: siblingsData?.listLearnInstances.items,
      }}
    >
      {children}
    </LearnContext.Provider>
  );
};

export const useLearn = () => {
  return useContext(LearnContext) as LearnContextContents;
};
