import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { CustomGrid } from "../../../../../components/Widget/Widget";
import { GetLearnInstancesWithMetricsByCaptainIdsQuery } from "../../../../../graphql/types";
import { LearnMetricsChart } from "../../../components/LearnMetricsChart";

export interface DailySuccessfulLoginsWidgetProps extends CustomGrid {
  readonly learns: GetLearnInstancesWithMetricsByCaptainIdsQuery["listLearnInstances"]["items"];
  readonly stackCharts: boolean;
}

export const DailySuccessfulLoginsWidget: FunctionComponent<
  DailySuccessfulLoginsWidgetProps
> = ({ learns, ...props }) => {
  const { t } = useTranslation();

  const metrics = learns.map((l) => ({
    learn: l,
    metrics: l.metrics.logins.metrics.map((m) => ({
      date: m.date,
      captainId: l.captainId,
      title: l.url,
      total: m.dailySuccessfulLogins,
    })),
  }));

  const today = learns.reduce(
    (a, b) =>
      a +
      b.metrics.logins.metrics[b.metrics.logins.metrics.length - 1]
        .dailySuccessfulLogins,
    0
  );

  return (
    <LearnMetricsChart
      {...props}
      strings={{
        title: t("learn.metrics.chart.dailySuccessfulLogins.title"),
        titleHelp: t("learn.metrics.chart.dailySuccessfulLogins.help.title"),
        peakUtilizationLabel: t(
          "learn.metrics.chart.dailySuccessfulLogins.help.peakUtilizationLabel"
        ),
        peakUtilizationHelp: t(
          "learn.metrics.chart.dailySuccessfulLogins.help.peakUtilizationHelp"
        ),
      }}
      valueFormatString={t(
        "learn.metrics.chart.dailySuccessfulLogins.valueFormatString"
      )}
      valueSuffixString={t(
        "learn.metrics.chart.dailySuccessfulLogins.valueSuffix"
      )}
      data={metrics}
      today={today}
      mix={true}
    />
  );
};
