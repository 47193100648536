import { AppBar, IconButton, Toolbar } from "@bb-ui/react-library";
import { Typography } from "@bb-ui/react-library/dist/components/Typography";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Logo } from "../components/Logo/Logo";
import { useHash } from "../features/LocationHash/LocationHashProvider";
import { SupportBackdrop } from "../features/support";

const useStyles = makeStyles(() => ({
  header: {
    gridArea: "header",
    boxShadow: "0 0 3px 0 rgba(0,0,0, 0.1)",
  },
  headerContent: {
    justifyContent: "space-between",
  },
  // TODO: revise when we have designs for smaller breakpoints
  // right now this will overloap other header content on smaller screens
  subTitle: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
  },
}));

export const Header: FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setKV, params } = useHash();

  const clickSupport = React.useCallback(() => {
    setKV("support", "true");
  }, [setKV]);

  const closeSupport = React.useCallback(() => {
    setKV("support", undefined);
  }, [setKV]);

  return (
    <>
      <SupportBackdrop
        open={params.get("support") === "true"}
        onClose={closeSupport}
      />
      <AppBar
        className={classes.header}
        position="sticky"
        color="inherit"
        data-testid="app-header"
      >
        <Toolbar className={classes.headerContent}>
          <Logo size={"large"} title={t("app.name")} />
          <Grid container justifyContent={"flex-end"} alignItems={"center"}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={clickSupport}
            >
              <Typography variant="h3" color="inherit" component="div">
                {t("help.link")}
              </Typography>
            </IconButton>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};
