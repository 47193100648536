import "@js-joda/timezone";
import {
  convert,
  DateTimeFormatter,
  ZonedDateTime,
  ZoneId,
} from "@js-joda/core";

export function toDate(date: string | undefined): Date {
  if (!date || date.length == 0) {
    return new Date();
  }
  const tt = ZonedDateTime.parse(date ?? "").withZoneSameInstant(
    ZoneId.of("SYSTEM")
  );
  return convert(tt).toDate();
}

export function toDateSameLocal(date: string | undefined): Date {
  if (!date || date.length == 0) {
    return new Date();
  }
  const tt = ZonedDateTime.parse(date ?? "").withZoneSameLocal(
    ZoneId.of("SYSTEM")
  );
  return convert(tt).toDate();
}

export function formatLocalTime(
  date: string | undefined,
  format: string
): string {
  return ZonedDateTime.parse(date ?? "")
    .withZoneSameInstant(ZoneId.of("SYSTEM"))
    .format(DateTimeFormatter.ofPattern(format));
}

export function formatSameDate(
  date: string | undefined,
  format: string
): string {
  return ZonedDateTime.parse(date ?? "")
    .withZoneSameLocal(ZoneId.of("SYSTEM"))
    .format(DateTimeFormatter.ofPattern(format));
}

export function toLocalString(date: Date, lang: string): string {
  return date.toLocaleString(lang, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
  });
}

export function toLocalDateString(date: Date, lang: string): string {
  return date.toLocaleString(lang, {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export function toLocalMonthDayString(date: Date, lang: string): string {
  return date.toLocaleString(lang, {
    month: "long",
    day: "numeric",
  });
}
export function toLocalMonthYearString(date: Date, lang: string): string {
  return date.toLocaleString(lang, {
    month: "long",
    year: "numeric",
  });
}
