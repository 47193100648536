import Grid from "@material-ui/core/Grid";
import { FunctionComponent } from "react";
import { GetLearnInstancesWithMetricsByCaptainIdsQuery } from "../../../graphql/types";
import { ActiveCoursesWidget } from "./widgets/CoursesWidget/ActiveCoursesWidget";
import { HistoricalCourses } from "./widgets/CoursesWidget/HistoricalCourses";
import { TotalCoursesWidget } from "./widgets/CoursesWidget/TotalCoursesWidget";

export interface LearnCoursesSectionProps {
  readonly learns: GetLearnInstancesWithMetricsByCaptainIdsQuery["listLearnInstances"]["items"];
  readonly stackCharts: boolean;
}

export const LearnCoursesSection: FunctionComponent<
  LearnCoursesSectionProps
> = ({ learns, ...props }) => {
  return (
    <Grid container direction={"row"} justifyContent={"flex-start"}>
      <HistoricalCourses
        data={learns.map((learn) => ({
          url: learn.url,
          lastUpdated: learn.lastUpdated,
          captainId: learn.captainId,
          metrics: learn.metrics,
        }))}
        xs={12}
      />
      <ActiveCoursesWidget
        data-test={"active-course-count-graph"}
        stackCharts={props.stackCharts}
        learns={learns}
        xs={12}
        sm={6}
      />
      <TotalCoursesWidget
        data-test={"total-course-count-graph"}
        stackCharts={props.stackCharts}
        learns={learns}
        xs={12}
        sm={6}
      />
    </Grid>
  );
};
