import {
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  PrimaryButton,
  Typography,
} from "@bb-ui/react-library";
import Grid from "@material-ui/core/Grid";
import { Email, Phone, Web } from "@material-ui/icons";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { SupportContact } from "../../graphql/types";
import { useLearn } from "../learn/useLearn";

export interface LearnSupportBackdropProps {
  readonly open: boolean;
  readonly onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    marginBottom: theme.spacing(4),
  },
  card: {
    display: "flex",
    flexDirection: "row",
    margin: theme.spacing(1),
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const SupportBackdrop: FunctionComponent<LearnSupportBackdropProps> = ({
  open,
  onClose,
}) => {
  const { learn } = useLearn();
  const { t } = useTranslation();
  const classes = useStyles();
  const supportContactEmail = t("help.sections.standard.email.value");
  const supportContactPhone = t("help.sections.standard.phone.value");
  const dialogId = "support";

  const supportContacts: SupportContact[] = learn?.support ?? [];

  return (
    <>
      <Dialog
        open={open}
        maxWidth="lg"
        onClose={onClose}
        aria-labelledby={`${dialogId}-dialog-title`}
      >
        <DialogTitle onClose={onClose} id={`${dialogId}-dialog-title`}>
          {t("help.sections.standard.title")}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {(() =>
            supportContacts.length > 0 && (
              <>
                <DialogContentText
                  variant={"h1"}
                  id={`${dialogId}-dialog-description`}
                >
                  {t("help.sections.team.title")}
                </DialogContentText>
                <Grid
                  container
                  justifyContent={"center"}
                  className={classes.cardContainer}
                >
                  {(() =>
                    supportContacts.map((contact) => (
                      <Card raised className={classes.card}>
                        {(() =>
                          contact.photo && (
                            <CardMedia>
                              <img src={contact.photo} alt={"Contact Photo"} />
                            </CardMedia>
                          ))()}
                        <CardContent
                          collapse="top"
                          className={classes.cardContent}
                        >
                          <Typography variant={"h2"}>
                            {contact.name ?? "Name Unavailable"}
                          </Typography>
                          <Typography variant={"subtitle1"}>
                            {t(`help.supportContactTypes.${contact.type}`)}
                          </Typography>
                          <Typography variant={"subtitle2"}>
                            {t("help.sections.team.email") + contact.email}
                          </Typography>
                          {(() =>
                            contact.phone && (
                              <Typography variant={"subtitle2"}>
                                {t("help.sections.team.phone") + contact.phone}
                              </Typography>
                            ))()}
                        </CardContent>
                      </Card>
                    )))()}
                </Grid>
              </>
            ))()}
          <Grid container direction={"column"} justifyContent={"center"}>
            <Grid item>
              <Link href={`mailto:${supportContactEmail}`}>
                <ListItem>
                  <ListItemIcon>
                    <Email />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography component="span" variant="subtitle2">
                      {t("help.sections.standard.email.label", {
                        email: supportContactEmail,
                      })}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>
            </Grid>
            <Grid item>
              <Link href={`tel:${supportContactPhone}`}>
                <ListItem>
                  <ListItemIcon>
                    <Phone />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography component="span" variant="subtitle2">
                      {t("help.sections.standard.phone.label", {
                        number: supportContactPhone,
                      })}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>
            </Grid>
            <Grid item>
              <Link
                href={t("help.sections.standard.web.value")}
                target="_blank"
              >
                <ListItem>
                  <ListItemIcon>
                    <Web />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography component="span" variant="subtitle2">
                      {t("help.sections.standard.web.label")}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>
            </Grid>
            <Grid item>
              <Link
                href={t("help.sections.standard.behindBlackboard.value")}
                target="_blank"
              >
                <ListItem>
                  <ListItemIcon>
                    <Web />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography component="span" variant="subtitle2">
                      {t("help.sections.standard.behindBlackboard.label")}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>
            </Grid>
            <Grid item>
              <Link
                href={t("help.sections.standard.community.value")}
                target="_blank"
              >
                <ListItem>
                  <ListItemIcon>
                    <Web />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography component="span" variant="subtitle2">
                      {t("help.sections.standard.community.label")}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>
            </Grid>
            <Grid item>
              <Link
                href={t("help.sections.standard.blackboardTechiesSlack.value")}
                target="_blank"
              >
                <ListItem>
                  <ListItemIcon>
                    <Web />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography component="span" variant="subtitle2">
                      {t("help.sections.standard.blackboardTechiesSlack.label")}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={onClose}>Close</PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
