import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { ErrorWidget } from "./components/ErrorPage";

export const FailedToFetchPage: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <ErrorWidget
      title={t("errorpages.health.failedTitle")}
      message={t("errorpages.health.failedMessage")}
    />
  );
};
