import React, { FunctionComponent } from "react";

export interface AccessibleImageProps
  extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, "alt" | "src"> {
  alt: string;
  src: string;
}

export const AccessibleImage: FunctionComponent<AccessibleImageProps> = ({
  alt,
  src,
  ...props
}) => {
  return <img alt={alt} src={src} aria-label={alt} {...props} />;
};
