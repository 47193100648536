import {
  FormControlLabel,
  ListItemText,
  NavigationList,
  NavigationListItem,
  NavigationListItemProps,
  Typography,
} from "@bb-ui/react-library";
import { Switch } from "@bb-ui/react-library/dist/components/Switch";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React, { ForwardRefExoticComponent, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes, useMatch } from "react-router";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ErrorMessage } from "../../../components/ErrorMessage/ErrorMessage";
import { HelpTooltip } from "../../../components/HelpTooltip/HelpTooltip";
import { CircularLoadingSuspense } from "../../../components/Loading/CircularLoadingSuspense";
import { Widget } from "../../../components/Widget/Widget";
import { useSuspendedQuery } from "../../../graphql/GraphqlProvider";
import { getLearnInstancesWithMetricsByCaptainIds } from "../../../graphql/queries";
import {
  GetLearnInstancesWithMetricsByCaptainIdsQuery,
  GetLearnInstancesWithMetricsByCaptainIdsQueryVariables,
} from "../../../graphql/types";
import { DownloadPDF } from "../components/DownloadPDF";
import { MultiLearnSelector } from "../components/MultiLearnSelector";
import { ResolutionPicker } from "../components/ResolutionPicker";
import { TimeSpanPicker } from "../components/TimeSpanPicker";
import {
  selectAvailableResolutions,
  selectTimeResolution,
  selectTimeStamp,
  setActiveCaptainIds,
  setMetricsChartResolution,
  setMetricsChartStacking,
  setMetricsChartTimeSpan,
  supportedResolutionSpans,
} from "../learnSlice";
import { useLearn } from "../useLearn";
import { LearnCoursesSection } from "./LearnCoursesSection";
import { LearnLoginSection } from "./LearnLoginSection";
import { LearnStorageSection } from "./LearnStorageSection";
import { LearnUserSection } from "./LearnUserSection";

const useStyles = makeStyles(() => ({
  legend: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    maxWidth: "100%",
    "& > li": {
      width: "inherit",
    },
  },
}));

export const LearnInsightsHomePage: FunctionComponent = () => {
  const captainIds = useAppSelector((s) => s.learn.captainIds);
  const dispatch = useAppDispatch();
  const match = useMatch("learn/insights/:page");
  const { siblings } = useLearn();
  const { t } = useTranslation();
  const classes = useStyles();
  const stackCharts = useAppSelector(
    (s) => s.learn.metrics?.controls?.stacking
  );
  const resolution = useAppSelector(selectTimeResolution);
  const availableTimeSpans = useAppSelector(selectAvailableResolutions);
  const timespan = useAppSelector(selectTimeStamp);
  console.log("timespan", Number(timespan));
  console.log("availableTimeSpans", availableTimeSpans);
  console.log("resolution", resolution);
  const handleSelect = React.useCallback(
    (ids: string[] | undefined) => {
      if (ids && ids.length) {
        dispatch(setActiveCaptainIds(ids));
      } else {
        dispatch(setActiveCaptainIds([]));
      }
    },
    [setActiveCaptainIds]
  );

  const handleStackDataSwitch = React.useCallback(
    (evt) => dispatch(setMetricsChartStacking(evt.target.checked)),
    [setMetricsChartStacking]
  );

  const handleResolutionChange = React.useCallback(
    (val) => dispatch(setMetricsChartResolution(val)),
    [setMetricsChartResolution]
  );

  const handleTimeSpanChange = React.useCallback(
    (val) => dispatch(setMetricsChartTimeSpan(val)),
    [setMetricsChartTimeSpan]
  );

  const NavigationListItemAugment: ForwardRefExoticComponent<
    Omit<NavigationListItemProps, "href"> & { to: string }
  > = NavigationListItem as any;

  return (
    <Grid container spacing={2} direction={"column"}>
      <Grid item md={12} lg={12}>
        <NavigationList navTagAriaLabel="menu" orientation="horizontal">
          <NavigationListItemAugment
            component={Link}
            to={`/learn/insights/storage`}
            data-test={"insights-storage"}
            active={match?.params.page === "storage"}
          >
            <ListItemText>
              <Typography component="span" variant="subtitle2">
                {t("learn.metrics.navigation.storage")}
              </Typography>
            </ListItemText>
          </NavigationListItemAugment>
          <NavigationListItemAugment
            component={Link}
            to={`/learn/insights/users`}
            data-test={"insights-users"}
            active={match?.params.page === "users"}
          >
            <ListItemText>
              <Typography component="span" variant="subtitle2">
                {t("learn.metrics.navigation.users")}
              </Typography>
            </ListItemText>
          </NavigationListItemAugment>
          <NavigationListItemAugment
            component={Link}
            to={`/learn/insights/logins`}
            data-test={"insights-logins"}
            active={match?.params.page === "logins"}
          >
            <ListItemText>
              <Typography component="span" variant="subtitle2">
                {t("learn.metrics.navigation.logins")}
              </Typography>
            </ListItemText>
          </NavigationListItemAugment>
          <NavigationListItemAugment
            component={Link}
            to={`/learn/insights/courses`}
            data-test={"insights-courses"}
            active={match?.params.page === "courses"}
          >
            <ListItemText>
              <Typography component="span" variant="subtitle2">
                {t("learn.metrics.navigation.courses")}
              </Typography>
            </ListItemText>
          </NavigationListItemAugment>
        </NavigationList>
      </Grid>
      <Grid item md={12} lg={12}>
        <Grid container direction={"row"} spacing={2}>
          <MultiLearnSelector
            captainIds={captainIds}
            siblings={siblings}
            onSelect={handleSelect}
            multiSelect
            xs={12}
            sm={8}
            md={8}
          />
          <Widget
            data-test={"charts-toolbar"}
            title={t("learn.metrics.chart.sections.controls.title")}
            titleHelp={t("learn.metrics.chart.sections.controls.help")}
            variant={"bordered"}
            titleVariant={"subtitle2"}
            md={4}
            sm={4}
            xs={12}
          >
            <Grid
              container
              className={classes.legend}
              direction={"column"}
              item
              xs={12}
            >
              <HelpTooltip
                help={t(
                  "learn.metrics.chart.sections.controls.stackToggle.help"
                )}
                icon={false}
              >
                <FormControlLabel
                  value={stackCharts}
                  htmlFor="stackDataSwitch"
                  control={
                    <Switch
                      id="stackDataSwitch"
                      checked={stackCharts}
                      onChange={handleStackDataSwitch}
                    />
                  }
                  label={t(
                    "learn.metrics.chart.sections.controls.stackToggle.label"
                  )}
                />
              </HelpTooltip>
            </Grid>
            <Grid item>
              <DownloadPDF name={`${match?.params.page}-insights`} />
            </Grid>
            <Grid item>
              <ResolutionPicker
                supportedResolutions={supportedResolutionSpans.map((opt) => ({
                  label: t(opt.label),
                  value: opt.value,
                }))}
                onChange={handleResolutionChange}
                value={resolution}
              />
            </Grid>
            <Grid item>
              <TimeSpanPicker
                supportedTimeSpans={availableTimeSpans.map((opt) => ({
                  label: t(opt.label),
                  value: opt.value,
                }))}
                onChange={handleTimeSpanChange}
                value={timespan}
              />
            </Grid>
          </Widget>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CircularLoadingSuspense>
          <LearnInsightsHomePageContents
            captainIds={captainIds}
            timespan={timespan}
            stackCharts={stackCharts}
          />
        </CircularLoadingSuspense>
      </Grid>
    </Grid>
  );
};

export const LearnInsightsHomePageContents: FunctionComponent<{
  captainIds: string[];
  timespan: number;
  stackCharts: boolean;
}> = ({ captainIds, timespan, stackCharts }) => {
  if (!captainIds || !captainIds.length) {
    return (
      <ErrorMessage
        title={"Missing Parameter"}
        message={"You must first select a Learn instance to view"}
      />
    );
  }

  const data = useSuspendedQuery<
    GetLearnInstancesWithMetricsByCaptainIdsQuery,
    GetLearnInstancesWithMetricsByCaptainIdsQueryVariables
  >(getLearnInstancesWithMetricsByCaptainIds, {
    variables: {
      captainIds,
      limit: Number(timespan),
    },
  });

  const learns = data?.listLearnInstances?.items;

  return (
    <Routes>
      <Route
        path={"storage"}
        element={
          <LearnStorageSection stackCharts={stackCharts} learns={learns} />
        }
      />
      <Route
        path={"users"}
        element={<LearnUserSection stackCharts={stackCharts} learns={learns} />}
      />
      <Route
        path={"logins"}
        element={
          <LearnLoginSection stackCharts={stackCharts} learns={learns} />
        }
      />
      <Route
        path={"courses"}
        element={
          <LearnCoursesSection stackCharts={stackCharts} learns={learns} />
        }
      />
      <Route path="*" element={<Navigate replace to="storage" />} />
    </Routes>
  );
};
